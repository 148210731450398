import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
//import logo from './logo.svg';
import IndexPage from './pages/indexPage';
import contractsJSON from "./abi.json";
import ImagePage from './pages/ImagePage';
import MetadataPage from './pages/MetadataPage';
//import './App.css';
import './assets/css/common.css';
import './assets/css/defaults.css';
import './assets/css/theme-dark.css';
import './assets/css/theme-dark-responsive.css';
import './assets/css/theme-lite.css';
import './assets/css/theme-lite-responsive.css';

  const App = () => {
  return (
    
    <Router>
      <Switch>
        <Route exact path="/" component={IndexPage} />
        <Route exact path="/nft/images/:filename" component={ImagePage} />
        <Route exact path="/nft/metadata/:tokenId" component={MetadataPage} />
      </Switch>
    </Router>
     
  );
}

export default App;
