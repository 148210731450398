import MetaMaskOnboarding from "@metamask/onboarding";
import Web3 from "web3";
import React, { useState, useRef, useEffect } from "react";
import contractsJSON from "../abi.json";
// import mug from "../assets/images/mug.png";
// import alert from "../assets/images/alert.png";
// import alertLite from "../assets/images/alertLite.png";
import muc from "../assets/images/muo.png";
import muvSmall from "../assets/images/muv.png";

// Codes Added
const ONBOARD_TEXT = "Click here to install MetaMask!";
const CONNECT_TEXT = "Connect To MetaMask";
const CONNECTED_TEXT = "Connected";

const MUAddress = "0xD036414fa2BCBb802691491E323BFf1348C5F4Ba";
const MUGAddress = "0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81";
const MUOAddress = "0x561f2209eA45023d796bF42F0402B33bc26610ce";
const MUVAddress = "0xdbA664085ae73CF4E4eb57954BDC88Be297B1f09";
const MUMAddress = "0x875E3352Baae84c38d930DD7Fd796178b2FCaa05";


const USDCAddress = "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E";
const USDCeAddress = "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664";
const USDTAddress = "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7";
const USDTeAddress = "0xc7198437980c041c805A1EDcbA50c1Ce5db95118";
const DAIeAddress = "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70";
const MIMAddress = "0x130966628846BFd36ff31a822705796e8cb8C18D";
const BUSDeAddress = "0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98";

const minABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
];

const coins = {
  MU: {
    type: "ERC20",
    options: {
      address: MUAddress,
      symbol: "MU",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xD036414fa2BCBb802691491E323BFf1348C5F4Ba/logo.png",
    },
  },
  MUG: {
    type: "ERC20",
    options: {
      address: MUGAddress,
      symbol: "MUG",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81/logo.png",
    },
  },
  MUO: {
    type: "ERC20",
    options: {
      address: MUOAddress,
      symbol: "MUO",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81/logo.png",
    },
  },
  MUV: {
    type: "ERC20",
    options: {
      address: MUVAddress,
      symbol: "MUV",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xdbA664085ae73CF4E4eb57954BDC88Be297B1f09/logo.png",
    },
  },
  MUM: {
    type: "ERC20",
    options: {
      address: MUMAddress,
      symbol: "MUM",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x875E3352Baae84c38d930DD7Fd796178b2FCaa05/logo.png",
    },
  },
};
// Codes Added

const MuVaultWrap = (props) => {
  // Codes Added
  // Might be removable
  const [buttonText, setButtonText] = React.useState(ONBOARD_TEXT);
  const [isDisabled, setDisabled] = React.useState(false);
  const [wrongNetwork, setWrongNetwork] = React.useState(false);
  // Might be removable

  const [muBalance, setMUBalance] = React.useState(0);
  const [mugBalance, setMUGBalance] = React.useState(0);
  const [muoBalance, setMUOBalance] = React.useState(0);
  const [muvBalance, setMUVBalance] = React.useState(0);
  const [mumBalance, setMUMBalance] = React.useState(0);
  const [accounts, setAccounts] = React.useState([]);
  const onboarding = React.useRef();

  const [muFaucetVal, setmuFaucetVal] = React.useState(0);
  const [muGoldWrapVal, setMuGoldWrapVal] = React.useState(0);

  const [approveDisabled, setApproveDisabled] = useState(false);
  const [swapDisabled, setSwapDisabled] = useState(true);

  const [amountApproved, setAmountApproved] = React.useState(false);
  const [amountIsApproving, setAmountIsApproving] = React.useState(false);
  const [amountIsConverting, setAmountIsConverting] = React.useState(false);

  const [muoToWithdraw, setMUOtoWithdraw] = useState(null);
  const [mUOAlreadyApprovedAmount, setMUOAlreadyApprovedAmount] = useState(0);
  const [amountMUVConverting, setAmountMUVConverting] = React.useState(false);
  const [amountConvertedMuVault, setAmountConvertedMuVault] = useState(false);

  React.useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
    // pastLogsOfMU();
  }, []);

  React.useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        setButtonText(CONNECTED_TEXT);
        setDisabled(true);
        onboarding.current.stopOnboarding();
        getBalance("MU");
        getBalance("MUG");
        getBalance("MUO");
        getBalance("MUV");
        getBalance("MUM");
      } else {
        setButtonText(CONNECT_TEXT);
        setDisabled(false);
        setMUBalance(0);
        setMUGBalance(0);
        setMUOBalance(0);
        setMUVBalance(0);
        setMUMBalance(0);
      }
    }
  }, [accounts]);

  React.useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length === 0 && window.ethereum.selectedAddress) {
        handleNewAccounts([window.ethereum.selectedAddress]);
        checkChainId();
      }
    }
  });

  const handleChainChanged = (_chainId) => {
    // checking if chain is of avalanche network
    if (_chainId !== "0xa86a") {
      setButtonText("Wrong Network! Select Avalanche");
      setDisabled(true);
      setWrongNetwork(true);
    } else {
      setWrongNetwork(false);
      if (window.ethereum.selectedAddress) {
        setButtonText(CONNECTED_TEXT);
        setDisabled(true);
        onboarding.current.stopOnboarding();
        getApprovedAmount();
      } else {
        setButtonText(CONNECT_TEXT);
        setDisabled(false);
      }
    }
  };
  const checkChainId = async () => {
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    handleChainChanged(chainId);
  };

  React.useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
      checkChainId();
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum.on("accountsChanged", handleNewAccounts);
      window.ethereum.on("chainChanged", handleChainChanged);
      return () => {
        window.ethereum.removeListener("accountsChanged", handleNewAccounts);
        window.ethereum.on("chainChanged", handleChainChanged);
      };
    }
  }, []);

  const addCoinToWallet = async (coin = "MU") => {
    try {
      await window.ethereum
        .request({
          method: "wallet_watchAsset",
          params: coins[coin],
        })
        .then(() => console.log("Success"));
    } catch (addError) {
      console.log(addError);
    }
  };

  const getBalance = async (type) => {
    try {
      let address = coins[type].options.address;
      let web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(minABI, address);
      const result = await contract.methods
        .balanceOf(window.ethereum.selectedAddress)
        .call();
      const format = web3.utils.fromWei(result);
      if (type === "MU") {
        setMUBalance(format);
      } else if (type === "MUG") {
        setMUGBalance(format);
      } else if (type === "MUO") {
        setMUOBalance(format);
      } else if (type === "MUV") {
        setMUVBalance(format);
      } else if (type === "MUM") {
        setMUMBalance(format);
      }
    } catch (addError) {
      console.log(addError);
    }
  };

  // const onClick = () => {
  //   if (MetaMaskOnboarding.isMetaMaskInstalled()) {
  //     window.ethereum
  //       .request({ method: "eth_requestAccounts" })
  //       .then((newAccounts) => setAccounts(newAccounts));
  //   } else {
  //     onboarding.current.startOnboarding();
  //   }
  // };

  // Put max value starts
  const setMUOMaxFn = () => {
    setMUOtoWithdraw(muoBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    setApproveBtnDisabled(false);
  };

  // Put max value ends

  /** Converting MUO to MUV */
  const approveMUO = async () => {
    try {
      const muoContractAddress = `0x561f2209eA45023d796bF42F0402B33bc26610ce`;
      const muvContractAddress = `0xdbA664085ae73CF4E4eb57954BDC88Be297B1f09`;
      const web3 = new Web3(window.ethereum);
      const weiValue = web3.utils.toWei(muoToWithdraw);
      // console.log ("the value added to conver is",muGoldWrapVal );
      setAmountIsApproving(true);

      const muoContract = new web3.eth.Contract(
        contractsJSON.muOREContract,
        muoContractAddress
      );
      console.log(muoContract);

      let transactionData = await muoContract.methods
        .approve(muvContractAddress, weiValue)
        .send({ from: window.ethereum.selectedAddress });
      console.log(transactionData);
      if (transactionData.status === true) {
        //alert("MU ORE Approved");
        setAmountIsApproving(false);

        setAmountApproved(true);
        setTimeout(() => {
          setAmountApproved(false);
        }, 4000);
        setSwapDisabled(false);
        setApproveDisabled(true);
        getApprovedAmount();
      } else {
        setAmountIsApproving(false);
      }
    } catch (err) {
      console.log(err);
      setAmountIsApproving(false);
    }
  };

  const swapMUV = async () => {
    try {
      const muvContractAddress = `0xdbA664085ae73CF4E4eb57954BDC88Be297B1f09`;
      const web3 = new Web3(window.ethereum);
      const weiValue = web3.utils.toWei(muoToWithdraw);
      setAmountIsConverting(true);
      const muvContract = new web3.eth.Contract(
        contractsJSON.muVAULTContract,
        muvContractAddress
      );
      console.log(muvContract);

      let transactionData = await muvContract.methods
        .depositFor(window.ethereum.selectedAddress, weiValue)
        .send({ from: window.ethereum.selectedAddress });

      console.log(transactionData);
      if (transactionData.status === true) {
        setAmountIsConverting(false);
        setSwapDisabled(true);
        setApproveDisabled(false);
        getApprovedAmount();
        getBalance("MUO");
        getBalance("MUV");
        setMuGoldWrapVal(null);
        setAmountConvertedMuVault(true);
        console.log("the success state is", amountConvertedMuVault);
      } else {
        setAmountIsConverting(false);
      }
    } catch (err) {
      console.log(err);
      setAmountIsConverting(false);
    }
  };

  const getApprovedAmount = () => {
    const muoContractAddress = `0x561f2209eA45023d796bF42F0402B33bc26610ce`;
    const muvContractAddress = `0xdbA664085ae73CF4E4eb57954BDC88Be297B1f09`;
    const web3 = new Web3(window.ethereum);

    const muoContract = new web3.eth.Contract(
      contractsJSON.muOREContract,
      muoContractAddress
    );
    console.log(muoContract);

    //console.log("Trying approved amount");
    muoContract.methods
      .allowance(window.ethereum.selectedAddress, muvContractAddress)
      .call()
      .then((data) => {
        //console.log("Already Approved Amount", data);
        setMUOAlreadyApprovedAmount(web3.utils.fromWei(data));
      });
    console.log(
      "the already approved balance value is Testings",
      mUOAlreadyApprovedAmount
    );
  };
  React.useEffect(() => {
    console.log(
      "the already approved balance value is Testings Next Test",
      mUOAlreadyApprovedAmount
    );
    console.log(
      "the inseted balance value is Testings Next Test Testings",
      muGoldWrapVal
    );
    if (mUOAlreadyApprovedAmount) {
      setMuGoldWrapVal(mUOAlreadyApprovedAmount);
      setSwapDisabled(false);
      setMUOtoWithdraw(mUOAlreadyApprovedAmount);
      setApproveDisabled(true);
      setApproveBtnDisabled(false);
      setDipositBtnDisabled(true);
    } else {
      setSwapDisabled(true);
      setApproveDisabled(false);
      setDipositBtnDisabled(true);
      setAmountConvertedMuVault(false);
    }
  }, []);

  /** Converting MUO to MUV ends here */

  let dollarUSLocale = Intl.NumberFormat("en-US");

  const [approveBtnDisabled, setApproveBtnDisabled] = useState(false);
  const [dipositBtnDisabled, setDipositBtnDisabled] = useState(true);

  const setApproveBtnDisabledFn = () => {
    setApproveBtnDisabled(true);
    setDipositBtnDisabled(false);
  };

  const setDipositBtnDisabledFn = () => {
    setDipositBtnDisabled(true);
    setAmountConvertedMuVault(true);
  };

  // Codes Added

  return (
    <div id="swapModal" className="modal muMoneyModal show-modal">
      <div
        className={
          amountConvertedMuVault
            ? "modal-content succesFailResult"
            : "modal-content"
        }
      >
        {amountConvertedMuVault ? (
          <div className="swappingWraper">
            <div className="modalContentFirstCondition">
              <div class="modalHedading">
                <span
                  id="swapModalclose"
                  className="close-button"
                  onClick={props.closeSwapMuVaultModal}
                >
                  <figure className="closeModal">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.121"
                      height="16.121"
                      viewBox="0 0 16.121 16.121"
                    >
                      <g
                        id="Group_147"
                        data-name="Group 147"
                        transform="translate(-676.999 -363.999)"
                      >
                        <line
                          id="Line_6"
                          data-name="Line 6"
                          x2="14"
                          y2="14"
                          transform="translate(678.06 365.06)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-width="1.5"
                        />
                        <line
                          id="Line_7"
                          data-name="Line 7"
                          x1="14"
                          y2="14"
                          transform="translate(678.06 365.06)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-width="1.5"
                        />
                      </g>
                    </svg>
                  </figure>
                </span>
              </div>
              <div class="modalSuccess">
                <h1>Congratulations !!</h1>
                <p>
                  {" "}
                  You received <span class="hilighted">{muoToWithdraw}</span> Mu
                  Vault
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="swappingWraper">
            {accounts.length ? (
              <div className="modalContentFirstCondition">
                {!wrongNetwork ? (
                  <div
                    className={
                      amountMUVConverting
                        ? "modalContentSecondCondition disabledClick"
                        : "modalContentSecondCondition"
                    }
                  >
                    <div className="modalHedading">
                      <span
                        className="conversionRate"
                        onClick={() => props.setMuGoldWrapFn()}
                      >
                        1 MU Ores = 1 MU Vault
                      </span>
                      <span
                        id="swapModalclose"
                        className="close-button"
                        onClick={props.closeSwapMuVaultModal}
                      >
                        <figure className="closeModal">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.121"
                            height="16.121"
                            viewBox="0 0 16.121 16.121"
                          >
                            <g
                              id="Group_147"
                              data-name="Group 147"
                              transform="translate(-676.999 -363.999)"
                            >
                              <line
                                id="Line_6"
                                data-name="Line 6"
                                x2="14"
                                y2="14"
                                transform="translate(678.06 365.06)"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-width="1.5"
                              />
                              <line
                                id="Line_7"
                                data-name="Line 7"
                                x1="14"
                                y2="14"
                                transform="translate(678.06 365.06)"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-width="1.5"
                              />
                            </g>
                          </svg>
                        </figure>
                      </span>
                    </div>
                    <div className="modalSubHedading">
                      <span className="wrapUnwrap">Deposit</span>
                      <span className="balanceRate">
                        Balance :{" "}
                        {dollarUSLocale.format(
                          muoBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
                        )}{" "}
                        MU Ore
                      </span>
                    </div>
                    <div className="modalBody">
                      <div className="firstConvertRow">
                        <figure className="currencyIcon">
                          <img src={muc} alt="" />
                          <span>MU Ore</span>
                        </figure>
                        <span className="inputFieldSection">
                          <input
                            type="number"
                            className="inputAmt"
                            placeholder="Amount"
                            min="1"
                            max="10"
                            value={muoToWithdraw}
                            onChange={(e) =>
                              setMUOtoWithdraw(e.currentTarget.value)
                            }
                          />
                        </span>
                        <span className="btnMax">
                          <button
                            onClick={() => setMUOMaxFn()}
                            className="maxButton valueBtn"
                          >
                            Max
                          </button>
                        </span>
                      </div>

                      <div className="alterBtnRow">
                        <figure className="swapIcons">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="46"
                            height="46"
                            viewBox="0 0 46 46"
                          >
                            <g
                              id="Group_155"
                              data-name="Group 155"
                              transform="translate(-937 -548)"
                            >
                              <circle
                                id="Ellipse_25"
                                data-name="Ellipse 25"
                                cx="23"
                                cy="23"
                                r="23"
                                transform="translate(937 548)"
                                fill="#151f3e"
                              />
                              <g id="sort" transform="translate(954 561)">
                                <path
                                  id="Path_3648"
                                  data-name="Path 3648"
                                  d="M5.663,20A2.325,2.325,0,0,1,4,19.309L.444,15.73a1.528,1.528,0,0,1,1.078-2.6H4.882V.781a.781.781,0,1,1,1.563,0V13.125a1.564,1.564,0,0,1-1.563,1.563H1.611l3.5,3.52a.778.778,0,0,0,1.1,0l3.5-3.52H4.944a.781.781,0,0,1,0-1.563H9.8a1.527,1.527,0,0,1,1.078,2.6L7.323,19.309a2.325,2.325,0,0,1-1.66.69Zm0,0"
                                  transform="translate(0 0)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_4281"
                                  data-name="Path 4281"
                                  d="M1.206,14.612l4.271,4.9,5.24-4.9Z"
                                  fill="none"
                                />
                              </g>
                            </g>
                          </svg>
                        </figure>
                      </div>

                      <div className="firstConvertRow">
                        <figure className="currencyIcon">
                          <img src={muvSmall} alt="" />
                          <span>MU Vault</span>
                        </figure>
                        <span className="inputFieldSection">
                          <input
                            type="number"
                            className="inputAmt"
                            value={muoToWithdraw}
                            placeholder="Amount"
                            readOnly
                          />
                        </span>
                      </div>

                      <p className="approvedSuccess">
                        {amountApproved ? (
                          <span>
                            Approved. You may now complete your deposit.
                          </span>
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </p>
                      {amountIsApproving ? (
                        <p className="approvingSuccess">
                          <span>
                            Please wait... Mu Ore conversion approval is in
                            pogress
                          </span>
                        </p>
                      ) : (
                        <div className="buttonDiv">
                          {amountIsConverting ? (
                            <p className="approvingSuccess">
                              <span>
                                Please wait... Mu Ore deposit is in pogress
                              </span>
                            </p>
                          ) : (
                            <div className="buttonWrapers">
                              {approveDisabled ? (
                                <button
                                  onClick={swapMUV}
                                  disabled={swapDisabled}
                                  className="swapBtn btn-gradient approved"
                                >
                                  Deposit
                                </button>
                              ) : (
                                <button
                                  onClick={approveMUO}
                                  disabled={approveDisabled}
                                  className="swapBtn btn-gradient"
                                >
                                  Approve
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="modalHedading">
                    <span className="conversionRate">
                      Wrong Network! Select Avalanche
                    </span>
                    <span
                      id="swapModalclose"
                      className="close-button"
                      onClick={props.closeSwapMuVaultModal}
                    >
                      <figure className="closeModal">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.121"
                          height="16.121"
                          viewBox="0 0 16.121 16.121"
                        >
                          <g
                            id="Group_147"
                            data-name="Group 147"
                            transform="translate(-676.999 -363.999)"
                          >
                            <line
                              id="Line_6"
                              data-name="Line 6"
                              x2="14"
                              y2="14"
                              transform="translate(678.06 365.06)"
                              fill="none"
                              stroke="#fff"
                              stroke-linecap="round"
                              stroke-width="1.5"
                            />
                            <line
                              id="Line_7"
                              data-name="Line 7"
                              x1="14"
                              y2="14"
                              transform="translate(678.06 365.06)"
                              fill="none"
                              stroke="#fff"
                              stroke-linecap="round"
                              stroke-width="1.5"
                            />
                          </g>
                        </svg>
                      </figure>
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div className="modalHedading logoutTexts">
                <span className="conversionRate">
                  Kindly Connect To MetaMask to access the Swaping feature.{" "}
                </span>
                <span
                  id="swapModalclose"
                  className="close-button"
                  onClick={props.closeSwapMuVaultModal}
                >
                  <figure className="closeModal">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.121"
                      height="16.121"
                      viewBox="0 0 16.121 16.121"
                    >
                      <g
                        id="Group_147"
                        data-name="Group 147"
                        transform="translate(-676.999 -363.999)"
                      >
                        <line
                          id="Line_6"
                          data-name="Line 6"
                          x2="14"
                          y2="14"
                          transform="translate(678.06 365.06)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-width="1.5"
                        />
                        <line
                          id="Line_7"
                          data-name="Line 7"
                          x1="14"
                          y2="14"
                          transform="translate(678.06 365.06)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-width="1.5"
                        />
                      </g>
                    </svg>
                  </figure>
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>

    // Swap Modal section
  );
};

export default MuVaultWrap;
