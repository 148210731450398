import MetaMaskOnboarding from "@metamask/onboarding";
import Web3 from "web3";
import React, { useState, useRef, useEffect } from "react";
import contractsJSON from "../abi.json";
import mug from "../assets/images/mug.png";
import muc from "../assets/images/muo.png";
import cross from "../assets/images/cross.png";
import convert from "../assets/images/convert.png";
// import Sidebarfile from './sidebarfile/Sidebarfile';

// Codes Added
const ONBOARD_TEXT = "Click here to install MetaMask!";
const CONNECT_TEXT = "Connect To MetaMask";
const CONNECTED_TEXT = "Connected";

const MUAddress = "0xD036414fa2BCBb802691491E323BFf1348C5F4Ba";
const MUGAddress = "0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81";
const MUOAddress = "0x561f2209eA45023d796bF42F0402B33bc26610ce";

const minABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
];

const coins = {
  MU: {
    type: "ERC20",
    options: {
      address: MUAddress,
      symbol: "MU",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xD036414fa2BCBb802691491E323BFf1348C5F4Ba/logo.png",
    },
  },
  MUG: {
    type: "ERC20",
    options: {
      address: MUGAddress,
      symbol: "MUG",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81/logo.png",
    },
  },
  MUO: {
    type: "ERC20",
    options: {
      address: MUOAddress,
      symbol: "MUO",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81/logo.png",
    },
  },
};
// Codes Added

const MuOreWrap = (props) => {
  // Codes Added
  // Might be removable
  const [buttonText, setButtonText] = React.useState(ONBOARD_TEXT);
  const [isDisabled, setDisabled] = React.useState(false);
  const [wrongNetwork, setWrongNetwork] = React.useState(false);
  // Might be removable

  const [muBalance, setMUBalance] = React.useState(0);
  const [mugBalance, setMUGBalance] = React.useState(0);
  const [muoBalance, setMUOBalance] = React.useState(0);
  const [accounts, setAccounts] = React.useState([]);
  const onboarding = React.useRef();

  const [muFaucetVal, setmuFaucetVal] = React.useState(0);
  const [muGoldWrapVal, setMuGoldWrapVal] = React.useState(0);

  const [approveDisabled, setApproveDisabled] = useState(false);
  const [swapDisabled, setSwapDisabled] = useState(true);

  const [muoToWithdraw, setMUOtoWithdraw] = useState(null);
  const [mUGAlreadyApprovedAmount, setMUGAlreadyApprovedAmount] = useState(0);
  const [amountMUOConverting, setAmountMUOConverting] = React.useState(false);

  React.useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
    // pastLogsOfMU();
  }, []);

  React.useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        setButtonText(CONNECTED_TEXT);
        setDisabled(true);
        onboarding.current.stopOnboarding();
        getBalance("MU");
        getBalance("MUG");
        getBalance("MUO");
      } else {
        setButtonText(CONNECT_TEXT);
        setDisabled(false);
        setMUBalance(0);
        setMUGBalance(0);
        setMUOBalance(0);
      }
    }
  }, [accounts]);

  React.useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length === 0 && window.ethereum.selectedAddress) {
        handleNewAccounts([window.ethereum.selectedAddress]);
        checkChainId();
      }
    }
  });

  const handleChainChanged = (_chainId) => {
    // checking if chain is of avalanche network
    if (_chainId !== "0xa86a") {
      setButtonText("Wrong Network! Select Avalanche");
      setDisabled(true);
      setWrongNetwork(true);
    } else {
      setWrongNetwork(false);
      if (window.ethereum.selectedAddress) {
        setButtonText(CONNECTED_TEXT);
        setDisabled(true);
        onboarding.current.stopOnboarding();
        getApprovedAmount();
      } else {
        setButtonText(CONNECT_TEXT);
        setDisabled(false);
      }
    }
  };
  const checkChainId = async () => {
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    handleChainChanged(chainId);
  };

  React.useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
      checkChainId();
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum.on("accountsChanged", handleNewAccounts);
      window.ethereum.on("chainChanged", handleChainChanged);
      return () => {
        window.ethereum.removeListener("accountsChanged", handleNewAccounts);
        window.ethereum.on("chainChanged", handleChainChanged);
      };
    }
  }, []);

  const addCoinToWallet = async (coin = "MU") => {
    try {
      await window.ethereum
        .request({
          method: "wallet_watchAsset",
          params: coins[coin],
        })
        .then(() => console.log("Success"));
    } catch (addError) {
      console.log(addError);
    }
  };

  const getBalance = async (type) => {
    try {
      let address = coins[type].options.address;
      let web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(minABI, address);
      const result = await contract.methods
        .balanceOf(window.ethereum.selectedAddress)
        .call();
      const format = web3.utils.fromWei(result);
      if (type === "MU") {
        setMUBalance(format);
      } else if (type === "MUG") {
        setMUGBalance(format);
      } else if (type === "MUO") {
        setMUOBalance(format);
      }
    } catch (addError) {
      console.log(addError);
    }
  };

  const onClick = () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((newAccounts) => setAccounts(newAccounts));
    } else {
      onboarding.current.startOnboarding();
    }
  };

  // Put max value starts
  const setMUOMaxFn = () => {
    setMUOtoWithdraw(muoBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  };

  // Put max value ends

  /** Converting MUO to MUG */
  const unWrapMUO = async () => {
    try {
      const muoContractAddress = `0x561f2209eA45023d796bF42F0402B33bc26610ce`;
      const web3 = new Web3(window.ethereum);
      const weiValue = web3.utils.toWei(muoToWithdraw);
      setAmountMUOConverting(true);
      const muoContract = new web3.eth.Contract(
        contractsJSON.muOREContract,
        muoContractAddress
      );
      console.log(muoContract);

      let transactionData = await muoContract.methods
        .withdrawTo(window.ethereum.selectedAddress, weiValue)
        .send({ from: window.ethereum.selectedAddress });
      if (transactionData.status === true) {
        // transactionHash
        //alert("Swap complete from MUO to MUG");
        setAmountMUOConverting(false);
        props.setAmountConverted(true);
        setSwapDisabled(true);
        setApproveDisabled(false);
        getBalance("MUG");
        getBalance("MUO");
      } else {
        setAmountMUOConverting(false);
      }
    } catch (err) {
      console.log(err);
      setAmountMUOConverting(false);
    }
  };
  /** Converting MUO to MUG ends here */

  const getApprovedAmount = () => {
    const mugContractAddress = `0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81`;
    const muoContractAddress = `0x561f2209eA45023d796bF42F0402B33bc26610ce`;
    const web3 = new Web3(window.ethereum);

    const mugContract = new web3.eth.Contract(
      contractsJSON.muGOLDContract,
      mugContractAddress
    );
    console.log(mugContract);

    //console.log("Trying approved amount");
    mugContract.methods
      .allowance(window.ethereum.selectedAddress, muoContractAddress)
      .call()
      .then((data) => {
       // console.log("Already Approved Amount", data);
        setMUGAlreadyApprovedAmount(web3.utils.fromWei(data));
      });
  };

  let dollarUSLocale = Intl.NumberFormat("en-US");

  // Codes Added

  return (
    <div
      className={
        props.amountConverted
          ? "modal-content succesFailResult"
          : "modal-content"
      }
    >
      {props.amountConverted ? (
        <div className="swappingWraper">
          <div className="modalContentFirstCondition">
            <div class="modalHedading">
              <span
                id="swapModalclose"
                className="close-button"
                onClick={props.closeSwapModal}
              >
                <figure className="closeModal">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.121"
                    height="16.121"
                    viewBox="0 0 16.121 16.121"
                  >
                    <g
                      id="Group_147"
                      data-name="Group 147"
                      transform="translate(-676.999 -363.999)"
                    >
                      <line
                        id="Line_6"
                        data-name="Line 6"
                        x2="14"
                        y2="14"
                        transform="translate(678.06 365.06)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-width="1.5"
                      />
                      <line
                        id="Line_7"
                        data-name="Line 7"
                        x1="14"
                        y2="14"
                        transform="translate(678.06 365.06)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-width="1.5"
                      />
                    </g>
                  </svg>
                </figure>
              </span>
            </div>
            <div class="modalSuccess">
              <h1>Congratulations !!</h1>
              <p>
                <span class="hilighted">Mu Gold</span> withdrawal is completed
              </p>
              {/* <button onClick={props.closeSwapModal} class="swapBtn btn-gradient">Convert Some More Now</button> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="swappingWraper">
          {accounts.length ? (
            <div className="modalContentFirstCondition">
              {!wrongNetwork ? (
                <div
                  className={
                    amountMUOConverting
                      ? "modalContentSecondCondition disabledClick"
                      : "modalContentSecondCondition"
                  }
                >
                  <div className="modalHedading">
                    <span
                      className="conversionRate"
                      onClick={() => props.setMuGoldWrapFn()}
                    >
                      1 MU Ore = 1 MU Gold
                    </span>
                    <span
                      id="swapModalclose"
                      className="close-button"
                      onClick={props.closeSwapModal}
                    >
                      <figure className="closeModal">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.121"
                          height="16.121"
                          viewBox="0 0 16.121 16.121"
                        >
                          <g
                            id="Group_147"
                            data-name="Group 147"
                            transform="translate(-676.999 -363.999)"
                          >
                            <line
                              id="Line_6"
                              data-name="Line 6"
                              x2="14"
                              y2="14"
                              transform="translate(678.06 365.06)"
                              fill="none"
                              stroke="#fff"
                              stroke-linecap="round"
                              stroke-width="1.5"
                            />
                            <line
                              id="Line_7"
                              data-name="Line 7"
                              x1="14"
                              y2="14"
                              transform="translate(678.06 365.06)"
                              fill="none"
                              stroke="#fff"
                              stroke-linecap="round"
                              stroke-width="1.5"
                            />
                          </g>
                        </svg>
                      </figure>
                    </span>
                  </div>
                  <div className="modalSubHedading">
                    <span className="wrapUnwrap">Withdrawal Mu Gold</span>
                    <span className="balanceRate">
                      Balance :{" "}
                      {dollarUSLocale.format(
                        muoBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
                      )}{" "}
                      MU Ore
                    </span>
                  </div>
                  <div className="modalBody">
                    <div className="firstConvertRow">
                      <figure className="currencyIcon">
                        <img src={muc} alt="" />
                        <span>MU Ore</span>
                      </figure>
                      <span className="inputFieldSection">
                        <input
                          type="number"
                          className="inputAmt"
                          placeholder="Amount"
                          min="1"
                          max="10"
                          value={muoToWithdraw}
                          onChange={(e) =>
                            setMUOtoWithdraw(e.currentTarget.value)
                          }
                        />
                      </span>
                      <span className="btnMax">
                        <button
                          onClick={() => setMUOMaxFn()}
                          className="maxButton valueBtn"
                        >
                          Max
                        </button>
                      </span>
                    </div>

                    <div className="alterBtnRow">
                      <figure
                        className="swapIcons"
                        onClick={() => props.setMuGoldWrapFn()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="46"
                          height="46"
                          viewBox="0 0 46 46"
                        >
                          <g
                            id="Group_155"
                            data-name="Group 155"
                            transform="translate(-937 -548)"
                          >
                            <circle
                              id="Ellipse_25"
                              data-name="Ellipse 25"
                              cx="23"
                              cy="23"
                              r="23"
                              transform="translate(937 548)"
                              fill="#151f3e"
                            />
                            <g id="sort" transform="translate(950.001 561)">
                              <path
                                id="Path_3647"
                                data-name="Path 3647"
                                d="M227.673,20a.781.781,0,0,1-.781-.781V6.875h-3.359a1.527,1.527,0,0,1-1.078-2.6L226.013.69a2.341,2.341,0,0,1,3.32,0l3.558,3.579a1.527,1.527,0,0,1-1.078,2.6h-.859a.781.781,0,0,1,0-1.563h.77l-3.5-3.52a.778.778,0,0,0-1.1,0l-3.5,3.52h3.27a1.564,1.564,0,0,1,1.563,1.563V19.219A.781.781,0,0,1,227.673,20Zm0,0"
                                transform="translate(-213.338)"
                                fill="#fff"
                              />
                              <path
                                id="Path_3648"
                                data-name="Path 3648"
                                d="M5.663,20A2.325,2.325,0,0,1,4,19.309L.444,15.73a1.528,1.528,0,0,1,1.078-2.6H4.882V.781a.781.781,0,1,1,1.563,0V13.125a1.564,1.564,0,0,1-1.563,1.563H1.611l3.5,3.52a.778.778,0,0,0,1.1,0l3.5-3.52h-.77a.781.781,0,0,1,0-1.563H9.8a1.527,1.527,0,0,1,1.078,2.6L7.323,19.309a2.325,2.325,0,0,1-1.66.69Zm0,0"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                            </g>
                          </g>
                        </svg>
                      </figure>
                    </div>

                    <div className="firstConvertRow">
                      <figure className="currencyIcon">
                        <img src={mug} alt="" />
                        <span>MU Gold</span>
                      </figure>
                      <span className="inputFieldSection">
                        <input
                          type="number"
                          className="inputAmt"
                          value={muoToWithdraw}
                          placeholder="Amount"
                          min="1"
                          max="10"
                          readOnly
                        />
                      </span>
                    </div>

                    <div className="buttonDiv">
                      {amountMUOConverting ? (
                        <p className="approvingSuccess">
                          <span>
                            Please wait... Mu Ore withdrawl is in pogress
                          </span>
                        </p>
                      ) : (
                        <button
                          onClick={unWrapMUO}
                          className="swapBtn btn-gradient"
                        >
                          Withdraw
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="modalHedading">
                  <span className="conversionRate">
                    Wrong Network! Select Avalanche
                  </span>
                  <span
                    id="swapModalclose"
                    className="close-button"
                    onClick={props.closeSwapModal}
                  >
                    <figure className="closeModal">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.121"
                        height="16.121"
                        viewBox="0 0 16.121 16.121"
                      >
                        <g
                          id="Group_147"
                          data-name="Group 147"
                          transform="translate(-676.999 -363.999)"
                        >
                          <line
                            id="Line_6"
                            data-name="Line 6"
                            x2="14"
                            y2="14"
                            transform="translate(678.06 365.06)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-width="1.5"
                          />
                          <line
                            id="Line_7"
                            data-name="Line 7"
                            x1="14"
                            y2="14"
                            transform="translate(678.06 365.06)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-width="1.5"
                          />
                        </g>
                      </svg>
                    </figure>
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div className="modalHedading">
              <span className="conversionRate logoutTexts">
                Kindly Connect To MetaMask to access the Swaping feature.{" "}
              </span>
              <span
                id="swapModalclose"
                className="close-button"
                onClick={props.closeSwapModal}
              >
                <figure className="closeModal">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.121"
                    height="16.121"
                    viewBox="0 0 16.121 16.121"
                  >
                    <g
                      id="Group_147"
                      data-name="Group 147"
                      transform="translate(-676.999 -363.999)"
                    >
                      <line
                        id="Line_6"
                        data-name="Line 6"
                        x2="14"
                        y2="14"
                        transform="translate(678.06 365.06)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-width="1.5"
                      />
                      <line
                        id="Line_7"
                        data-name="Line 7"
                        x1="14"
                        y2="14"
                        transform="translate(678.06 365.06)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-width="1.5"
                      />
                    </g>
                  </svg>
                </figure>
              </span>
            </div>
          )}
        </div>
      )}
    </div>

    // Swap Modal section
  );
};

export default MuOreWrap;
