import React, { useState, useRef, useEffect } from "react";
import MetaMaskOnboarding from "@metamask/onboarding";
import Web3 from "web3";
import contractsJSON from "../abi.json";
import usdce from "../assets/images/usdc.e-1@2x.png";
import daie from "../assets/images/DAI.e@2x.png";
import usdt from "../assets/images/usdt@2x.png";
import mim from "../assets/images/MIM@2x.png";
import busd from "../assets/images/BUSD.e@2x.png";
import mum from "../assets/images/Mu_money_Logo_5_PNG@2x.png";
import alert from "../assets/images/alert.png";
import alertLite from "../assets/images/alertLite.png";

// Codes Added
const ONBOARD_TEXT = "Click here to install MetaMask!";
const CONNECT_TEXT = "Connect To MetaMask";
const CONNECTED_TEXT = "Connected";

const MUAddress = "0xD036414fa2BCBb802691491E323BFf1348C5F4Ba";
const MUGAddress = "0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81";
const MUOAddress = "0x561f2209eA45023d796bF42F0402B33bc26610ce";
const MUVAddress = "0xdbA664085ae73CF4E4eb57954BDC88Be297B1f09";
const MUMAddress = "0x875E3352Baae84c38d930DD7Fd796178b2FCaa05";
const MUBAddress = "0xA472Ed02182ED5B1C71D803d0Fed098F0C7C77A9";


const USDCAddress = "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E";
const USDCeAddress = "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664";
const USDTAddress = "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7";
const USDTeAddress = "0xc7198437980c041c805A1EDcbA50c1Ce5db95118";
const DAIeAddress = "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70";
const MIMAddress = "0x130966628846BFd36ff31a822705796e8cb8C18D";
const BUSDeAddress = "0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98";




// Codes Added

const MuMoneyWrap = (props) => {
  // Codes Added
  // States used to check if the connection to metamask walet is on or off
  const [buttonText, setButtonText] = React.useState(ONBOARD_TEXT);
  const [isDisabled, setDisabled] = React.useState(false);
  const [wrongNetwork, setWrongNetwork] = React.useState(false);
  // States used to check if the connection to metamask walet is on or off


  const [accounts, setAccounts] = React.useState([]);
  const onboarding = React.useRef();

  
  const [stableCoinWrapVal, setStableCoinWrapVal] = React.useState(null);

  const [approveDisabled, setApproveDisabled] = useState(false);
  const [swapDisabled, setSwapDisabled] = useState(true);

  const [amountApproved, setAmountApproved] = React.useState(false);
  const [amountIsApproving, setAmountIsApproving] = React.useState(false);
  const [amountIsConverting, setAmountIsConverting] = React.useState(false);

  
  const [mUMAlreadyApprovedAmount, setMUMAlreadyApprovedAmount] = useState(0);
  
  

  React.useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
    // pastLogsOfMU();
  }, []);

  
  React.useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length === 0 && window.ethereum.selectedAddress) {
        handleNewAccounts([window.ethereum.selectedAddress]);
        checkChainId();
      }
    }
  });

  const handleChainChanged = (_chainId) => {
    // checking if chain is of avalanche network
    if (_chainId !== "0xa86a") {
      setButtonText("Wrong Network! Select Avalanche");
      setDisabled(true);
      setWrongNetwork(true);
    } else {
      setWrongNetwork(false);
      if (window.ethereum.selectedAddress) {
        setButtonText(CONNECTED_TEXT);
        setDisabled(true);
        onboarding.current.stopOnboarding();
        getApprovedAmount();
      } else {
        setButtonText(CONNECT_TEXT);
        setDisabled(false);
      }
    }
  };
  const checkChainId = async () => {
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    handleChainChanged(chainId);
  };

  React.useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
      checkChainId();
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum.on("accountsChanged", handleNewAccounts);
      window.ethereum.on("chainChanged", handleChainChanged);
      return () => {
        window.ethereum.removeListener("accountsChanged", handleNewAccounts);
        window.ethereum.on("chainChanged", handleChainChanged);
      };
    }
  }, []);


  // This function is used to get the approval from Stable coin selected for its convertion to Mu Money

    /** Converting StableCoin to MUM */
    const approveStableCoin = async () => {
      try {
        const selectedCoinContractAddress = stableCoinSelectedAddress;
        const mubContractAddress = `0xA472Ed02182ED5B1C71D803d0Fed098F0C7C77A9`;
        const web3 = new Web3(window.ethereum);
        const weiValue = web3.utils.toWei(stableCoinWrapVal);       
        //    console.log ("the value added to conver is",stableCoinWrapVal );
        setAmountIsApproving(true);
  
        const mubContract = new web3.eth.Contract(
          contractsJSON.muBankContract,
          selectedCoinContractAddress
        );
        console.log(mubContract);
  
        let transactionData = await mubContract.methods
          .approve(mubContractAddress, weiValue)
          .send({ from: window.ethereum.selectedAddress });
        console.log(transactionData);
        if (transactionData.status === true) {
          setAmountIsApproving(false);
  
          setAmountApproved(true);
          setTimeout(() => {
            setAmountApproved(false);
          }, 4000);
          setSwapDisabled(false);
          setApproveDisabled(true);
          getApprovedAmount();
        } else {
          setAmountIsApproving(false);
        }
      } catch (err) {
        console.log(err);
        setAmountIsApproving(false);
      }
    };
    // This function is used to get the Swap the approved Selected stable coin to MU Money by using MU bank Contract
  
    const swapMUM = async () => {
      try {
        const mubContractAddress = `0xA472Ed02182ED5B1C71D803d0Fed098F0C7C77A9`;
        const web3 = new Web3(window.ethereum);
        const weiValue = web3.utils.toWei(stableCoinWrapVal);
        setAmountIsConverting(true);
        const mubContract = new web3.eth.Contract(
          contractsJSON.muBankContract,
          mubContractAddress
        );
        console.log(mubContract);
        const selectedCoinContractAddress = stableCoinSelectedAddress;
        let transactionData = await mubContract.methods
          .stable_coin_bond(selectedCoinContractAddress, weiValue)
          .send({ from: window.ethereum.selectedAddress });
  
        console.log(transactionData);
        if (transactionData.status === true) {
          setAmountIsConverting(false);
          setSwapDisabled(true);
          setApproveDisabled(false);
          getApprovedAmount();
          // getBalance("MUM");
          setStableCoinWrapVal(null);
          setAmountConvertedMuMoney(true);
        } else {
          setAmountIsConverting(false);
        }
      } catch (err) {
        console.log(err);
        setAmountIsConverting(false);
      }
    };

  
    const getApprovedAmount = () => {
      const selectedCoinContractAddress = stableCoinSelectedAddress;
        const mubContractAddress = `0xA472Ed02182ED5B1C71D803d0Fed098F0C7C77A9`;
      const web3 = new Web3(window.ethereum);
  
      const mubContract = new web3.eth.Contract(
        contractsJSON.muBankContract,
        selectedCoinContractAddress
      );
      console.log("The approving contract is",mubContract);
  
      //console.log("Trying approved amount");
      mubContract.methods
        .allowance(window.ethereum.selectedAddress, mubContractAddress)
        .call()
        .then((data) => {
          console.log("Already Approved Amount", data);
          setMUMAlreadyApprovedAmount(web3.utils.fromWei(data));
        });
      console.log(
        "the already approved balance value is Testings",
        mUMAlreadyApprovedAmount
      );
    };
    React.useEffect(() => {
      console.log(
        "the already approved balance value is Testings Next Test",
        mUMAlreadyApprovedAmount
      );
      console.log(
        "the inseted balance value is Testings Next Test Testings",
        stableCoinWrapVal
      );
      if (mUMAlreadyApprovedAmount) {
        setStableCoinWrapVal(mUMAlreadyApprovedAmount);
        setSwapDisabled(false);
        setStableCoinWrapVal(mUMAlreadyApprovedAmount);
        setApproveDisabled(true);
        setApproveBtnDisabled(false);
        setDipositBtnDisabled(true);
      } else {
        setSwapDisabled(true);
        setApproveDisabled(false);
        setDipositBtnDisabled(true);
      }
    }, []);
  
    /** Converting StableCoin to MUM ends here */


 // This function is used to get the swaping from the stable coins with  decimal values to MU Money


    // Converting the coins with decimal places 6



    const approveStableCoinWith6Decimals= async () => {
      try {
        const selectedCoinContractAddress = stableCoinSelectedAddress;
        const mubContractAddress = `0xA472Ed02182ED5B1C71D803d0Fed098F0C7C77A9`;
        const web3 = new Web3(window.ethereum);
        const weiValue = web3.utils.toWei(stableCoinWrapVal,"mwei");       
        console.log ("the value added to conver is",stableCoinWrapVal );
        setAmountIsApproving(true);
  
        const mubContract = new web3.eth.Contract(
          contractsJSON.muBankContract,
          selectedCoinContractAddress
        );
        console.log(mubContract);
  
        let transactionData = await mubContract.methods
          .approve(mubContractAddress, weiValue)
          .send({ from: window.ethereum.selectedAddress });
        console.log(transactionData);
        if (transactionData.status === true) {
          //alert("MU ORE Approved");
          setAmountIsApproving(false);
  
          setAmountApproved(true);
          setTimeout(() => {
            setAmountApproved(false);
          }, 4000);
          setSwapDisabled(false);
          setApproveDisabled(true);
          getApprovedAmount();
        } else {
          setAmountIsApproving(false);
        }
      } catch (err) {
        console.log(err);
        setAmountIsApproving(false);
      }
    };

  
    // Converting ends with coins with decimal places 6
  // Codes Added
  let dollarUSLocale = Intl.NumberFormat("en-US");

  const [stableCoinSelected, setStableCoinSelected] = useState(0);
  const [stableCoinSelectedAddress, setStableCoinSelectedAddress] = useState();
  const setStableCoinSelectedFn = (divNum, coinConractAddress) => () => {
    setStableCoinSelected(divNum);
    setStableCoinSelectedAddress(coinConractAddress)
    console.log("The selected coin address is ", stableCoinSelectedAddress)
    setApproveBtnDisabled(false);
    // setStableCoinWrapVal("")
  };

  const [approveBtnDisabled, setApproveBtnDisabled] = useState(true);
  const [dipositBtnDisabled, setDipositBtnDisabled] = useState(true);
  const [amountConvertedMuMoney, setAmountConvertedMuMoney] = useState(false);

//This is the function for the max button where the max values of the individual stable coins are stored

    // Put max value starts
    const setPutMaxFnUSDC = () => {
      setStableCoinWrapVal(props.usdcBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    };

    const setPutMaxFnUSDCE = () => {
      setStableCoinWrapVal(props.usdceBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    };

    const setPutMaxFnDAIE = () => {
      setStableCoinWrapVal(props.daieBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    };

    const setPutMaxFnUSDT = () => {
      setStableCoinWrapVal(props.usdtBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    };

    const setPutMaxFnUSDTE = () => {
      setStableCoinWrapVal(props.usdteBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    };

    const setPutMaxFnMIM = () => {
      setStableCoinWrapVal(props.mimBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    };

    const setPutMaxFnBUSDE = () => {
      setStableCoinWrapVal(props.busdeBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    };

  return (
    <div
      className={
        amountConvertedMuMoney
          ? "modal-content succesFailResult"
          : "modal-content"
      }
    >
      {amountConvertedMuMoney ? (
        <div className="swappingWraper">
          <div className="modalContentFirstCondition">
            <div class="modalHedading">
              <span
                id="swapModalclose"
                className="close-button"
                onClick={props.closeSwapMuMoneyModal}
              >
                <figure className="closeModal">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.121"
                    height="16.121"
                    viewBox="0 0 16.121 16.121"
                  >
                    <g
                      id="Group_147"
                      data-name="Group 147"
                      transform="translate(-676.999 -363.999)"
                    >
                      <line
                        id="Line_6"
                        data-name="Line 6"
                        x2="14"
                        y2="14"
                        transform="translate(678.06 365.06)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        stroke-width="1.5"
                      />
                      <line
                        id="Line_7"
                        data-name="Line 7"
                        x1="14"
                        y2="14"
                        transform="translate(678.06 365.06)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        stroke-width="1.5"
                      />
                    </g>
                  </svg>
                </figure>
              </span>
            </div>
            <div class="modalSuccess">
              <h1>Congratulations !!</h1>
              <p>
              <span class="hilighted">Mu Money</span> deposit is completed
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="swappingWraper">
          {accounts.length ? (
            <div className="modalContentFirstCondition">
              {!wrongNetwork ? (
                <div className="modalContentSecondCondition muMoneyConvertion">
                  {!props.whiteListStatusValue && (
                    <div className="whiteListWraper">
                      <div className="whiteListInnerWraper">
                        <div className="whiteListBoxInfo">
                          <div className="whiteListIcon">
                            <img src={alert} alt="" className="darkAlert" />
                            <img src={alertLite} alt="" className="liteAlert" />
                          </div>
                          <div className="whiteLabelText">
                            <p>
                              Hey, first you need to whitelist your contract address, to do that please contact the admin.
                            </p>
                            <button
                              onClick={props.setWhitelistContactFn}
                              className="maxButton btn-gradient halfBtn"
                            >
                              Got it!
                              {props.whitelistContact}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="modalHedading">
                    <span className="conversionRate muMoneyModalHeading">
                      Bond your stable coin to get Mu money
                    </span>
                    {props.whiteListStatusValue && (
                    <span
                      id="swapModalclose"
                      className="close-button"
                      onClick={props.closeSwapMuMoneyModal}
                    >
                      <figure className="closeModal">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.121"
                          height="16.121"
                          viewBox="0 0 16.121 16.121"
                        >
                          <g
                            id="Group_147"
                            data-name="Group 147"
                            transform="translate(-676.999 -363.999)"
                          >
                            <line
                              id="Line_6"
                              data-name="Line 6"
                              x2="14"
                              y2="14"
                              transform="translate(678.06 365.06)"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              stroke-width="1.5"
                            />
                            <line
                              id="Line_7"
                              data-name="Line 7"
                              x1="14"
                              y2="14"
                              transform="translate(678.06 365.06)"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                            />
                          </g>
                        </svg>
                      </figure>
                    </span>
                    )}
                  </div>
                  <div className="modalSubHedading muMoneyModal">
                    <span className="wrapUnwrap">Select stable coin</span>
                    {stableCoinSelected == 0 && (
                         <span className="balanceRate">Balance : Not selected</span>
                    )}
                    {stableCoinSelected == 1 && (
                         <span className="balanceRate">Balance : {dollarUSLocale.format(
                          props.usdcBalance
                            .toString()
                            .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                        )}</span>
                    )}
                    {stableCoinSelected == 2 && (
                         <span className="balanceRate">Balance : {dollarUSLocale.format(
                          props.usdceBalance
                            .toString()
                            .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                        )}</span>
                    )}
                    {stableCoinSelected == 3 && (
                         <span className="balanceRate">Balance : {dollarUSLocale.format(
                          props.daieBalance
                            .toString()
                            .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                        )}</span>
                    )}
                    {stableCoinSelected == 4 && (
                         <span className="balanceRate">Balance : {dollarUSLocale.format(
                          props.usdtBalance
                            .toString()
                            .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                        )}</span>
                    )}
                    {stableCoinSelected == 5 && (
                         <span className="balanceRate">Balance : {dollarUSLocale.format(
                          props.usdteBalance
                            .toString()
                            .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                        )}</span>
                    )}
                    {stableCoinSelected == 6 && (
                         <span className="balanceRate">Balance : {dollarUSLocale.format(
                          props.mimBalance
                            .toString()
                            .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                        )}</span>
                    )}
                    {stableCoinSelected == 7 && (
                         <span className="balanceRate">Balance : {dollarUSLocale.format(
                          props.busdeBalance
                            .toString()
                            .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                        )}</span>
                    )}
                  </div>
                  <div className="modalBody">
                    <div className="stableCoinRow">
                      <div
                        id={props.converstionStatusUSDC ? "accepted": "notAccepted"}
                        className={
                          stableCoinSelected == 1
                            ? "indStableCoinsWraper activated"
                            : "indStableCoinsWraper"
                        }
                        onClick={setStableCoinSelectedFn(1, USDCAddress)}
                      >
                        <div className="stablecoinInfo">
                          <img src={usdce} alt="" />
                          <p>USDC</p>
                        </div>
                        <h4>{dollarUSLocale.format(
                                    props.usdcBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}</h4>
                      </div>

                      <div
                        id={props.converstionStatusUSDCe ? "accepted": "notAccepted"}
                        className={
                          stableCoinSelected == 2
                            ? "indStableCoinsWraper activated"
                            : "indStableCoinsWraper"
                        }
                        onClick={setStableCoinSelectedFn(2, USDCeAddress)}
                      >
                        <div className="stablecoinInfo">
                          <img src={usdce} alt="" />
                          <p>USDC.e</p>
                        </div>
                        <h4>{dollarUSLocale.format(
                                    props.usdceBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}</h4>
                      </div>

                      <div
                        id={props.converstionStatusDAIe ? "accepted": "notAccepted"}
                        className={
                          stableCoinSelected == 3
                            ? "indStableCoinsWraper activated"
                            : "indStableCoinsWraper"
                        }
                        onClick={setStableCoinSelectedFn(3, DAIeAddress)}
                      >
                        <div className="stablecoinInfo">
                          <img src={daie} alt="" />
                          <p>DAI.e</p>
                        </div>
                        <h4>{dollarUSLocale.format(
                                    props.daieBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}</h4>
                      </div>

                      <div
                       id={props.converstionStatusUSDT ? "accepted": "notAccepted"}
                        className={
                          stableCoinSelected == 4
                            ? "indStableCoinsWraper activated"
                            : "indStableCoinsWraper"
                        }
                        onClick={setStableCoinSelectedFn(4, USDTAddress)}
                      >
                        <div className="stablecoinInfo">
                          <img src={usdt} alt="" />
                          <p>USDT</p>
                        </div>
                        <h4>{dollarUSLocale.format(
                                    props.usdtBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}</h4>
                      </div>

                      <div
                       id={props.converstionStatusUSDTe ? "accepted": "notAccepted"}
                        className={
                          stableCoinSelected == 5
                            ? "indStableCoinsWraper activated"
                            : "indStableCoinsWraper"
                        }
                        onClick={setStableCoinSelectedFn(5, USDTeAddress)}
                      >
                        <div className="stablecoinInfo">
                          <img src={usdt} alt="" />
                          <p>USDT.e</p>
                        </div>
                        <h4>{dollarUSLocale.format(
                                    props.usdteBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}</h4>
                      </div>

                      <div
                       id={props.converstionStatusMIM ? "accepted": "notAccepted"}
                        className={
                          stableCoinSelected == 6
                            ? "indStableCoinsWraper activated"
                            : "indStableCoinsWraper"
                        }
                        onClick={setStableCoinSelectedFn(6, MIMAddress)}
                      >
                        <div className="stablecoinInfo">
                          <img src={mim} alt="" />
                          <p>MIM</p>
                        </div>
                        <h4>{dollarUSLocale.format(
                                    props.mimBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}</h4>
                      </div>

                      <div
                       id={props.converstionStatusBUSDe ? "accepted": "notAccepted"}
                        className={
                          stableCoinSelected == 7
                            ? "indStableCoinsWraper activated"
                            : "indStableCoinsWraper"
                        }
                        onClick={setStableCoinSelectedFn(7, BUSDeAddress)}
                      >
                        <div className="stablecoinInfo">
                          <img src={busd} alt="" />
                          <p>BUSD.e</p>
                        </div>
                        <h4>{dollarUSLocale.format(
                                    props.busdeBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}</h4>
                      </div>
                    </div>

                    <div className="firstConvertRow">
                      {/* <figure className="currencyIcon">
                    <img src={mum} alt="" />
                    <span>MU Gold</span>
                </figure> */}
                      {stableCoinSelected == 1 && (
                        <figure className="currencyIcon">
                          <img src={usdce} alt="" />
                          <span>USDC</span>
                        </figure>
                      )}

                      {stableCoinSelected == 2 && (
                        <figure className="currencyIcon">
                          <img src={usdce} alt="" />
                          <span>USDC.e</span>
                        </figure>
                      )}

                      {stableCoinSelected == 3 && (
                        <figure className="currencyIcon">
                          <img src={daie} alt="" />
                          <span>DAI.e</span>
                        </figure>
                      )}

                      {stableCoinSelected == 4 && (
                        <figure className="currencyIcon">
                          <img src={usdt} alt="" />
                          <span>USDT</span>
                        </figure>
                      )}

                      {stableCoinSelected == 5 && (
                        <figure className="currencyIcon">
                          <img src={usdt} alt="" />
                          <span>USDT.e</span>
                        </figure>
                      )}

                      {stableCoinSelected == 6 && (
                        <figure className="currencyIcon">
                          <img src={mim} alt="" />
                          <span>MIM</span>
                        </figure>
                      )}

                      {stableCoinSelected == 7 && (
                        <figure className="currencyIcon">
                          <img src={busd} alt="" />
                          <span>BUSD.e</span>
                        </figure>
                      )}
                      <span className="inputFieldSection">
                        <input
                          type="number"
                          className="inputAmt"
                          placeholder="Select the stable coin"
                          min="1"
                          value={stableCoinWrapVal}
                          onChange={(e) =>
                            setStableCoinWrapVal(e.currentTarget.value)
                          }
                        />
                      </span>
                      <span className="btnMax">
                        {stableCoinSelected == 0 && (
                          <button className="maxButton valueBtn disabledMaxBtn">Max</button>
                        )}
                        {stableCoinSelected == 1  && (
                          <button
                          onClick={() => setPutMaxFnUSDC()}
                          className="maxButton valueBtn">Max</button>
                        )}
                        {stableCoinSelected == 2  && (
                          <button
                          onClick={() => setPutMaxFnUSDCE()}
                          className="maxButton valueBtn">Max</button>
                        )}
                        {stableCoinSelected == 3  && (
                          <button
                          onClick={() => setPutMaxFnDAIE()}
                          className="maxButton valueBtn">Max</button>
                        )}
                        {stableCoinSelected == 4  && (
                          <button
                          onClick={() => setPutMaxFnUSDT()}
                          className="maxButton valueBtn">Max</button>
                        )}
                        {stableCoinSelected == 5  && (
                          <button
                          onClick={() => setPutMaxFnUSDTE()}
                          className="maxButton valueBtn">Max</button>
                        )}
                        {stableCoinSelected == 6  && (
                          <button
                          onClick={() => setPutMaxFnMIM()}
                          className="maxButton valueBtn">Max</button>
                        )}
                        {stableCoinSelected == 7  && (
                          <button
                          onClick={() => setPutMaxFnBUSDE()}
                          className="maxButton valueBtn">Max</button>
                        )}
                      </span>
                    </div>

                    <div className="alterBtnRow">
                      <figure
                        className="swapIcons"
                        onClick={() => props.setMuStableCoinWrapFn()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="46"
                          height="46"
                          viewBox="0 0 46 46"
                        >
                          <g
                            id="Group_155"
                            data-name="Group 155"
                            transform="translate(-937 -548)"
                          >
                            <circle
                              id="Ellipse_25"
                              data-name="Ellipse 25"
                              cx="23"
                              cy="23"
                              r="23"
                              transform="translate(937 548)"
                              fill="#151f3e"
                            />
                            <g id="sort" transform="translate(950.001 561)">
                              <path
                                id="Path_3647"
                                data-name="Path 3647"
                                d="M227.673,20a.781.781,0,0,1-.781-.781V6.875h-3.359a1.527,1.527,0,0,1-1.078-2.6L226.013.69a2.341,2.341,0,0,1,3.32,0l3.558,3.579a1.527,1.527,0,0,1-1.078,2.6h-.859a.781.781,0,0,1,0-1.563h.77l-3.5-3.52a.778.778,0,0,0-1.1,0l-3.5,3.52h3.27a1.564,1.564,0,0,1,1.563,1.563V19.219A.781.781,0,0,1,227.673,20Zm0,0"
                                transform="translate(-213.338)"
                                fill="#fff"
                              />
                              <path
                                id="Path_3648"
                                data-name="Path 3648"
                                d="M5.663,20A2.325,2.325,0,0,1,4,19.309L.444,15.73a1.528,1.528,0,0,1,1.078-2.6H4.882V.781a.781.781,0,1,1,1.563,0V13.125a1.564,1.564,0,0,1-1.563,1.563H1.611l3.5,3.52a.778.778,0,0,0,1.1,0l3.5-3.52h-.77a.781.781,0,0,1,0-1.563H9.8a1.527,1.527,0,0,1,1.078,2.6L7.323,19.309a2.325,2.325,0,0,1-1.66.69Zm0,0"
                                transform="translate(0 0)"
                                fill="#fff"
                              />
                            </g>
                          </g>
                        </svg>
                      </figure>
                    </div>

                    <div className="firstConvertRow">
                      <figure className="currencyIcon">
                        <img src={mum} alt="" />
                        <span>Mu money</span>
                      </figure>
                      <span className="inputFieldSection">
                        <input
                          type="number"
                          className="inputAmt readOnly"
                          value={stableCoinWrapVal}
                          placeholder="Amount"
                          min="1"
                          readOnly
                        />
                      </span>
                      
                    </div>

                   
                      <p className="approvedSuccess">
                        {amountApproved ? (
                          <span>
                            Approved. You may now complete your deposit.
                          </span>
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </p>
                      {amountIsApproving ? (
                        <p className="approvingSuccess">
                          <span>
                            Please wait... Your conversion approval is in
                            pogress
                          </span>
                        </p>
                      ) : (
                        <div className="buttonDiv">
                          {amountIsConverting ? (
                            <p className="approvingSuccess">
                              <span>
                                Please wait... Mu Money deposit is in pogress
                              </span>
                            </p>
                          ) : (
                            <div className="buttonWrapers">
                              {approveDisabled ? (
                                <button
                                  onClick={swapMUM}
                                  disabled={swapDisabled}
                                  className="swapBtn btn-gradient approved"
                                >
                                  Deposit
                                </button>
                              ) : (
                                <>
                                {(stableCoinSelected === 5 || stableCoinSelected === 1 || stableCoinSelected === 2 || stableCoinSelected === 4 ) ? (
                                <button
                                  onClick={approveStableCoinWith6Decimals}
                                  disabled={approveDisabled}
                                  className="swapBtn btn-gradient"
                                >
                                  Approve
                                </button>
                                ):(
                                  <button
                                  onClick={approveStableCoin}
                                  disabled={approveDisabled}
                                  className="swapBtn btn-gradient"
                                >
                                  Approve
                                </button>
                                )}
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                     
                    

                  </div>
                </div>
              ) : (
                <div className="modalHedading">
                  <span className="conversionRate">
                    Wrong Network! Select Avalanche
                  </span>
                  <span
                    id="swapModalclose"
                    className="close-button"
                    onClick={props.closeSwapMuMoneyModal}
                  >
                    <figure className="closeModal">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.121"
                        height="16.121"
                        viewBox="0 0 16.121 16.121"
                      >
                        <g
                          id="Group_147"
                          data-name="Group 147"
                          transform="translate(-676.999 -363.999)"
                        >
                          <line
                            id="Line_6"
                            data-name="Line 6"
                            x2="14"
                            y2="14"
                            transform="translate(678.06 365.06)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            stroke-width="1.5"
                          />
                          <line
                            id="Line_7"
                            data-name="Line 7"
                            x1="14"
                            y2="14"
                            transform="translate(678.06 365.06)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            stroke-width="1.5"
                          />
                        </g>
                      </svg>
                    </figure>
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div className="modalHedading">
              <span className="conversionRate logoutTexts">
                Kindly Connect To MetaMask to access the Swaping feature.{" "}
              </span>
              <span
                id="swapModalclose"
                className="close-button"
                onClick={props.closeSwapMuMoneyModal}
              >
                <figure className="closeModal">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.121"
                    height="16.121"
                    viewBox="0 0 16.121 16.121"
                  >
                    <g
                      id="Group_147"
                      data-name="Group 147"
                      transform="translate(-676.999 -363.999)"
                    >
                      <line
                        id="Line_6"
                        data-name="Line 6"
                        x2="14"
                        y2="14"
                        transform="translate(678.06 365.06)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        stroke-width="1.5"
                      />
                      <line
                        id="Line_7"
                        data-name="Line 7"
                        x1="14"
                        y2="14"
                        transform="translate(678.06 365.06)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        stroke-width="1.5"
                      />
                    </g>
                  </svg>
                </figure>
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MuMoneyWrap;
