import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const MetadataPage = ({ match }) => {
  const { tokenId } = match.params;
  const [metadata, setMetadata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const response = await fetch(`/nft/metadata/${tokenId}.json`);
        if (!response.ok) throw new Error('Metadata not found');
        const data = await response.json();
        setMetadata(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMetadata();
  }, [tokenId]);

  if (loading) return <div>Loading metadata...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="metadata-container">
      <pre>{JSON.stringify(metadata, null, 2)}</pre>
    </div>
  );
};

export default withRouter(MetadataPage);