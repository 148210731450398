import React, { useState } from "react";
import MuMoneyWrap from "./muMoneyWrap";
import MuStableCoinWrap from "./muStableCoinWrap";

const SwapMuMoneyModal = (props) => {
  const [muMoneyWrap, setMuMoneyWrap] = useState(true);
  const [muStableCoinWrap, setMuStableCoinWrap] = useState(false);

  const setMuMoneyWrapFn = () => {
    setMuMoneyWrap(true);
    setMuStableCoinWrap(false);
  };

  const setMuStableCoinWrapFn = () => {
    setMuStableCoinWrap(true);
    setMuMoneyWrap(false);
  };

  // const setMuGoldWrapFn = () => {
  //   setMuGoldWrap(true);
  //   setMuOreWrap(false);
  // }

  // const setMuOreWrapFn = () => {
  //   setMuOreWrap(true);
  //   setMuGoldWrap(false);
  // }

  return (
    //  Swap Modal section
    <div id="swapModal" className="modal muMoneyModal show-modal">
      {muMoneyWrap && (
        <MuMoneyWrap
          closeSwapMuMoneyModal={props.closeSwapMuMoneyModal}
          setMuStableCoinWrapFn={setMuStableCoinWrapFn}
          setAmountConverted={props.setAmountConverted}
          amountConverted={props.amountConverted}
          setWhitelistContactFn={props.setWhitelistContactFn}
          whitelistContact={props.whitelistContact}
          whiteListStatusValue ={props.whiteListStatusValue}
          converstionStatusUSDC={props.converstionStatusUSDC}
          converstionStatusUSDCe={props.converstionStatusUSDCe}
          converstionStatusUSDT={props.converstionStatusUSDT}
          converstionStatusUSDTe={props.converstionStatusUSDTe}
          converstionStatusDAIe={props.converstionStatusDAIe}
          converstionStatusMIM={props.converstionStatusMIM}
          converstionStatusBUSDe={props.converstionStatusBUSDe}
          usdcBalance={props.usdcBalance}
          usdceBalance={props.usdceBalance}
          usdtBalance={props.usdtBalance}
          usdteBalance={props.usdteBalance}
          daieBalance={props.daieBalance}
          mimBalance={props.mimBalance}
          busdeBalance={props.busdeBalance}
        />
      )}

      {muStableCoinWrap && (
        <MuStableCoinWrap
          closeSwapMuMoneyModal={props.closeSwapMuMoneyModal}
          setMuMoneyWrapFn={setMuMoneyWrapFn}
          setAmountConverted={props.setAmountConverted}
          amountConverted={props.amountConverted}
          setWhitelistContactFn={props.setWhitelistContactFn}
          whitelistContact={props.whitelistContact}
          converstionStatusUSDC={props.converstionStatusUSDC}
          converstionStatusUSDCe={props.converstionStatusUSDCe}
          converstionStatusUSDT={props.converstionStatusUSDT}
          converstionStatusUSDTe={props.converstionStatusUSDTe}
          converstionStatusDAIe={props.converstionStatusDAIe}
          converstionStatusMIM={props.converstionStatusMIM}
          converstionStatusBUSDe={props.converstionStatusBUSDe}
          // usdcBalance={props.usdcBalance}
          // usdceBalance={props.usdceBalance}
          // usdtBalance={props.usdtBalance}
          // usdteBalance={props.usdteBalance}
          // daieBalance={props.daieBalance}
          // mimBalance={props.mimBalance}
          // busdeBalance={props.busdeBalance}
          usdcBankBalance={props.usdcBankBalance}
          usdceBankBalance={props.usdceBankBalance}
          usdtBankBalance={props.usdtBankBalance}
          usdteBankBalance={props.usdteBankBalance}
          daieBankBalance={props.daieBankBalance}
          mimBankBalance={props.mimBankBalance}
          busdeBankBalance={props.busdeBankBalance}
          mumBalance={props.mumBalance}


          converstionStatusBankUSDC={props.converstionStatusBankUSDC}
          converstionStatusBankUSDCe={props.converstionStatusBankUSDCe}
          converstionStatusBankUSDT={props.converstionStatusBankUSDT}
          converstionStatusBankUSDTe={props.converstionStatusBankUSDTe}
          converstionStatusBankDAIe={props.converstionStatusBankDAIe}
          converstionStatusBankMIM={props.converstionStatusBankMIM}
          converstionStatusBankBUSDe={props.converstionStatusBankBUSDe}
        />
      )}
    </div>

    // Swap Modal section
  );
};

export default SwapMuMoneyModal;
