 //import React, { useState, useEffect } from "react";
import logo from "../assets/images/logo-white.png";
// import goLiteIcon from "../assets/images/goLite.svg";
// import goDarkIcon from "../assets/images/goDarkIcon.svg";
import foxImg from "../assets/images/Group131.svg";
import mucSmall from "../assets/images/muc.png";
import mugSmall from "../assets/images/mug.png";
import muoSmall from "../assets/images/muo.png";
import muvSmall from "../assets/images/muv.png";
import mumSmall from "../assets/images/muMoney.png";
import { Helmet } from "react-helmet";
import SwapMuMoneyModal from "./swapMuMoneyModal";

// code added

import MetaMaskOnboarding from "@metamask/onboarding";
import Web3 from "web3";
import React, { useState, useRef, useEffect } from "react";
//import contractsJSON from "../abi.json";

const ONBOARD_TEXT = "Click here to install MetaMask!";
const CONNECT_TEXT = "Connect To MetaMask";
const CONNECTED_TEXT = "Avalanche";

const MUAddress = "0xD036414fa2BCBb802691491E323BFf1348C5F4Ba";
const MUGAddress = "0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81";
const MUOAddress = "0x561f2209eA45023d796bF42F0402B33bc26610ce";
const MUVAddress = "0xdbA664085ae73CF4E4eb57954BDC88Be297B1f09";
const MUMAddress = "0x875E3352Baae84c38d930DD7Fd796178b2FCaa05";
const MUBAddress = "0xA472Ed02182ED5B1C71D803d0Fed098F0C7C77A9";

const USDCAddress = "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E";
const USDCeAddress = "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664";
const USDTAddress = "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7";
const USDTeAddress = "0xc7198437980c041c805A1EDcbA50c1Ce5db95118";
const DAIeAddress = "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70";
const MIMAddress = "0x130966628846BFd36ff31a822705796e8cb8C18D";
const BUSDeAddress = "0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98";

const minABIM = [
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [{ name: "supply", type: "uint256" }],
    type: "function",
  },
];

const minABIW = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "isWhitelisted",
    outputs: [{ name: "status", type: "bool" }],
    type: "function",
  },
];


const minABIAConvetAllowed = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "check_if_approved_stable_coin",
    outputs: [{ name: "_is_approved_stable_coin", type: "bool" }],
    type: "function",
  },
];

const minABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
];

const minABIStableCoins = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
];

const coins = {
  MU: {
    type: "ERC20",
    options: {
      address: MUAddress,
      symbol: "MU",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xD036414fa2BCBb802691491E323BFf1348C5F4Ba/logo.png",
    },
  },
  MUG: {
    type: "ERC20",
    options: {
      address: MUGAddress,
      symbol: "MUG",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81/logo.png",
    },
  },
  MUO: {
    type: "ERC20",
    options: {
      address: MUOAddress,
      symbol: "MUO",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81/logo.png",
    },
  },
  MUV: {
    type: "ERC20",
    options: {
      address: MUVAddress,
      symbol: "MUV",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xdbA664085ae73CF4E4eb57954BDC88Be297B1f09/logo.png",
    },
  },
  MUM: {
    type: "ERC20",
    options: {
      address: MUMAddress,
      symbol: "MUM",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x875E3352Baae84c38d930DD7Fd796178b2FCaa05/logo.png",
    },
  },
  USDC: {
    type: "ERC20",
    options: {
      address: USDCAddress,
      symbol: "USDC",
      decimals: 6,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E/logo.png",
    },
  },
  USDCE: {
    type: "ERC20",
    options: {
      address: USDCeAddress,
      symbol: "USDCE",
      decimals: 6,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664/logo.png",
    },
  },
  USDT: {
    type: "ERC20",
    options: {
      address: USDTAddress,
      symbol: "USDT",
      decimals: 6,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7/logo.png",
    },
  },
  USDTe: {
    type: "ERC20",
    options: {
      address: USDTeAddress,
      symbol: "USDTe",
      decimals: 6,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xc7198437980c041c805A1EDcbA50c1Ce5db95118/logo.png",
    },
  },
  DAIe: {
    type: "ERC20",
    options: {
      address: DAIeAddress,
      symbol: "DAIe",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xd586E7F844cEa2F87f50152665BCbc2C279D8d70/logo.png",
    },
  },
  MIM: {
    type: "ERC20",
    options: {
      address: MIMAddress,
      symbol: "MIM",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x130966628846BFd36ff31a822705796e8cb8C18D/logo.png",
    },
  },
  BUSDe: {
    type: "ERC20",
    options: {
      address: BUSDeAddress,
      symbol: "BUSDe",
      decimals: 18,
      image:
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98/logo.png",
    },
  }
};

// codes added

/**
 *
 * @param {object} props
 * @returns
 */
const Header = (props) => {
  // const [swapModal, setSwapModal] = useState(false);

  // const setSwapModalFn = () => {
  //   props.swapModal();
  // };

  // const closeSwapModal = () => {
  //   setSwapModal(false);
  // }

  const [swapMuMoneyModal, setSwapMuMoneyModal] = useState(false);
  const [whitelistContact, setWhitelistContact] = useState(true);

  const setSwapMuMoneyModalFn = () => {
    setSwapMuMoneyModal(true);
  };
  
//This function is called when the success modal of the convertion of MU Money from Stable coin and vice versa is closed updating all the present values of individual coins

  const closeSwapMuMoneyModal = () => {
    setSwapMuMoneyModal(false);
    getBalance("MU");
        getBalance("MUG");
        getBalance("MUO");
        getBalance("MUV");
        getBalance("MUM");
        getBalanceUSDC("USDC");
        getBalanceUSDC("USDCE");
        getBalanceUSDC("USDT");
        getBalanceUSDC("USDTe");
        getBalance("DAIe");
        getBalance("MIM");
        getBalance("BUSDe");

        getBankBalanceUSDC("USDC");
        getBankBalanceUSDC("USDCE");
        getBankBalanceUSDC("USDT");
        getBankBalanceUSDC("USDTe");
        getBankBalance("DAIe");
        getBankBalance("MIM");
        getBankBalance("BUSDe");

        setconverstionStatusBankUSDC(usdcBankBalance);
        setconverstionStatusBankUSDCe(usdceBankBalance);
        setconverstionStatusBankUSDT(usdtBankBalance);
        setconverstionStatusBankUSDTe(usdteBankBalance);
        setconverstionStatusBankDAIe(daieBankBalance);
        setconverstionStatusBankMIM(mimBankBalance);
        setconverstionStatusBankBUSDe(busdeBankBalance);
        

        getTotalSupply("MU");
        getTotalSupply("MUG");
        getTotalSupply("MUO");
        getTotalSupply("MUV");
  };

  const setWhitelistContactFn = () => {
    
    //setWhitelistContact();
    setSwapMuMoneyModal(false);
    //console.log("The state")
  };

  const [goLite, setGoLite] = useState(false);
  const setGoLiteFn = () => {
    setGoLite(true);
    props.goLite();
  };

  // my code
  const [nullBalance, setNullBalance] = React.useState(false);
  const [loggedin, setLoggedin] = React.useState(false);
  const [loggedinDropDown, setLoggedinDropDown] = React.useState(false);
  const dropDownDisplayRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClick, true);
  }, []);

  const handleClick = (e) => {
    if (
      dropDownDisplayRef.current &&
      !dropDownDisplayRef.current.contains(e.target)
    ) {
      setLoggedinDropDown(false);
    } else {
      setLoggedinDropDown(true);
    }
  };

  // my code
  // code added

  const [buttonText, setButtonText] = React.useState(ONBOARD_TEXT);
  const [isDisabled, setDisabled] = React.useState(false);
  const [wrongNetwork, setWrongNetwork] = React.useState(false);
  const [muBalance, setMUBalance] = React.useState(0);
  const [mugBalance, setMUGBalance] = React.useState(0);
  const [muoBalance, setMUOBalance] = React.useState(0);
  const [muvBalance, setMUVBalance] = React.useState(0);

  const [usdcBalance, setUSDCBalance] = React.useState(0);
  const [usdceBalance, setUSDCEBalance] = React.useState(0);
  const [usdtBalance, setUSDTBalance] = React.useState(0);
  const [usdteBalance, setUSDTEBalance] = React.useState(0);
  const [daieBalance, setDAIEBalance] = React.useState(0);
  const [mimBalance, setMIMBalance] = React.useState(0);
  const [busdeBalance, setBUSDEBalance] = React.useState(0);



  const [usdcBankBalance, setUSDCBankBalance] = React.useState(0);
  const [usdceBankBalance, setUSDCEBankBalance] = React.useState(0);
  const [usdtBankBalance, setUSDTBankBalance] = React.useState(0);
  const [usdteBankBalance, setUSDTEBankBalance] = React.useState(0);
  const [daieBankBalance, setDAIEBankBalance] = React.useState(0);
  const [mimBankBalance, setMIMBankBalance] = React.useState(0);
  const [busdeBankBalance, setBUSDEBankBalance] = React.useState(0);

  const [whiteListStatusValue, setWhiteListStatusValue] = React.useState(0);

  const [converstionStatusUSDC, setconverstionStatusUSDC] = React.useState(0);
  const [converstionStatusUSDCe, setconverstionStatusUSDCe] = React.useState(0);
  const [converstionStatusUSDT, setconverstionStatusUSDT] = React.useState(0);
  const [converstionStatusUSDTe, setconverstionStatusUSDTe] = React.useState(0);
  const [converstionStatusDAIe, setconverstionStatusDAIe] = React.useState(0);
  const [converstionStatusMIM, setconverstionStatusMIM] = React.useState(0);
  const [converstionStatusBUSDe, setconverstionStatusBUSDe] = React.useState(0);



  const [converstionStatusBankUSDC, setconverstionStatusBankUSDC] = React.useState(0);
  const [converstionStatusBankUSDCe, setconverstionStatusBankUSDCe] = React.useState(0);
  const [converstionStatusBankUSDT, setconverstionStatusBankUSDT] = React.useState(0);
  const [converstionStatusBankUSDTe, setconverstionStatusBankUSDTe] = React.useState(0);
  const [converstionStatusBankDAIe, setconverstionStatusBankDAIe] = React.useState(0);
  const [converstionStatusBankMIM, setconverstionStatusBankMIM] = React.useState(0);
  const [converstionStatusBankBUSDe, setconverstionStatusBankBUSDe] = React.useState(0);
  

  const [muMinedBalance, setMuMinedBalance] = React.useState(0);
  const [mugMinedBalance, setMugMinedBalance] = React.useState(0);
  const [muoMinedBalance, setMuoMinedBalance] = React.useState(0);
  const [muvMinedBalance, setMuvMinedBalance] = React.useState(0);
  const [mumMinedBalance, setMumMinedBalance] = React.useState(0);

  
  const [mumBalance, setMUMBalance] = React.useState(0);
  const [accounts, setAccounts] = React.useState([]);
  const onboarding = React.useRef();

  const [muFaucetVal, setmuFaucetVal] = React.useState(0);
  const [muGoldWrapVal, setMuGoldWrapVal] = React.useState(0);

  const [approveDisabled, setApproveDisabled] = useState(false);
  const [swapDisabled, setSwapDisabled] = useState(true);

  const [muoToWithdraw, setMUOtoWithdraw] = useState(0);
  const [mUGAlreadyApprovedAmount, setMUGAlreadyApprovedAmount] = useState(0);
  // const [totalBalanace, setTotalBalance] = useState(0);
  React.useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
    // pastLogsOfMU();
  }, []);

  React.useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        setButtonText(CONNECTED_TEXT);
        setDisabled(true);
        onboarding.current.stopOnboarding();
        getBalance("MU");
        getBalance("MUG");
        getBalance("MUO");
        getBalance("MUV");
        getBalance("MUM");
        getBalanceUSDC("USDC");
        getBalanceUSDC("USDCE");
        getBalanceUSDC("USDT");
        getBalanceUSDC("USDTe");
        getBalance("DAIe");
        getBalance("MIM");
        getBalance("BUSDe");

        getBankBalanceUSDC("USDC");
        getBankBalanceUSDC("USDCE");
        getBankBalanceUSDC("USDT");
        getBankBalanceUSDC("USDTe");
        getBankBalance("DAIe");
        getBankBalance("MIM");
        getBankBalance("BUSDe");

        setconverstionStatusBankUSDC(usdcBankBalance);
        setconverstionStatusBankUSDCe(usdceBankBalance);
        setconverstionStatusBankUSDT(usdtBankBalance);
        setconverstionStatusBankUSDTe(usdteBankBalance);
        setconverstionStatusBankDAIe(daieBankBalance);
        setconverstionStatusBankMIM(mimBankBalance);
        setconverstionStatusBankBUSDe(busdeBankBalance);



        whitelistStatus();
        convertStatusUSDCAddress();
        convertStatusUSDCeAddress();
        convertStatusUSDTAddress();
        convertStatusUSDTeAddress();
        convertStatusDAIeAddress();
        convertStatusMIMAddress();
        convertStatusBUSDeAddress();
        

        getTotalSupply("MU");
        getTotalSupply("MUG");
        getTotalSupply("MUO");
        getTotalSupply("MUV");
        //getTotalSupply(true);
      } else {
        setButtonText(CONNECT_TEXT);
        setDisabled(false);
        setMUBalance(0);
        setMUGBalance(0);
        setMUOBalance(0);
        getBalance(0);
        getBalanceUSDC(0);
        getBankBalanceUSDC(0);
        setconverstionStatusBankUSDC(0);
        setconverstionStatusBankUSDCe(0);
        setconverstionStatusBankUSDT(0);
        setconverstionStatusBankUSDTe(0);
        setconverstionStatusBankDAIe(0);
        setconverstionStatusBankMIM(0);
        setconverstionStatusBankBUSDe(0);
        getBankBalance(0);
        getTotalSupply(0);
        setWhiteListStatusValue(false)
        setLoggedin(false);
      }
    }
  }, [accounts]);

  React.useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length === 0 && window.ethereum.selectedAddress) {
        handleNewAccounts([window.ethereum.selectedAddress]);
        checkChainId();
      }
    }
  });

  const handleChainChanged = (_chainId) => {
    // checking if chain is of avalanche network
    if (_chainId !== "0xa86a") {
      setButtonText("Wrong Network! Select Avalanche");
      setDisabled(true);
      setLoggedin(true);
      setWrongNetwork(true);
      setMUBalance(0);
      setMUGBalance(0);
      setMUOBalance(0);
      setMUVBalance(0);
      setMUMBalance(0);
    } else {
      setWrongNetwork(false);
      getBalance("MU");
      getBalance("MUG");
      getBalance("MUO");
      getBalance("MUV");
      getBalance("MUM");
      getBalance("MU");
      getBalance("MUG");
      getBalance("MUO");
      getBalance("MUV");
      getBalance("MUM");
      getBalanceUSDC("USDC");
      getBalanceUSDC("USDCE");
      getBalanceUSDC("USDT");
      getBalanceUSDC("USDTe");
      getBalance("DAIe");
      getBalance("MIM");
      getBalance("BUSDe");
      getBankBalanceUSDC("USDC");
      getBankBalanceUSDC("USDCE");
      getBankBalanceUSDC("USDT");
      getBankBalanceUSDC("USDTe");
      getBankBalance("DAIe");
      getBankBalance("MIM");
      getBankBalance("BUSDe");

      setconverstionStatusBankUSDC(usdcBankBalance);
      setconverstionStatusBankUSDCe(usdceBankBalance);
      setconverstionStatusBankUSDT(usdtBankBalance);
      setconverstionStatusBankUSDTe(usdteBankBalance);
      setconverstionStatusBankDAIe(daieBankBalance);
      setconverstionStatusBankMIM(mimBankBalance);
      setconverstionStatusBankBUSDe(busdeBankBalance);


      getTotalSupply("MU");
      getTotalSupply("MUG");
      getTotalSupply("MUO");
      getTotalSupply("MUV");
      if (window.ethereum.selectedAddress) {
        setButtonText(CONNECTED_TEXT);
        setDisabled(true);
        setLoggedin(true);
        onboarding.current.stopOnboarding();
       // getApprovedAmount();
      } else {
        setButtonText(CONNECT_TEXT);
        setDisabled(false);
        setLoggedin(false);
      }
    }
  };
  const checkChainId = async () => {
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    handleChainChanged(chainId);
  };

  React.useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
      checkChainId();
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum.on("accountsChanged", handleNewAccounts);
      window.ethereum.on("chainChanged", handleChainChanged);
      return () => {
        window.ethereum.removeListener("accountsChanged", handleNewAccounts);
        window.ethereum.on("chainChanged", handleChainChanged);
      };
    }
  }, []);

  const addCoinToWallet = async (coin = "MU") => {
    try {
      await window.ethereum
        .request({
          method: "wallet_watchAsset",
          params: coins[coin],
        })
        .then(() => console.log("Success"));
    } catch (addError) {
      console.log(addError);
    }
  };

  const getBalance = async (type) => {
    try {
      let address = coins[type].options.address;
      let web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(minABI, address);
      const result = await contract.methods
        .balanceOf(window.ethereum.selectedAddress)
        .call();
      const format = web3.utils.fromWei(result);
      if (type === "MU") {
        setMUBalance(format);
      } else if (type === "MUG") {
        setMUGBalance(format);
      } else if (type === "MUO") {
        setMUOBalance(format);
      } else if (type === "MUV") {
        setMUVBalance(format);
      } else if (type === "MUM") {
        setMUMBalance(format);
      }
      else if (type === "DAIe") {
        setDAIEBalance(format);
      }
      else if (type === "MIM") {
        setMIMBalance(format);
      }else if (type === "BUSDe") {
        setBUSDEBalance(format);
      }
    } catch (addError) {
      console.log(addError);
    }
  };



  const getBalanceUSDC = async (type) => {
    try {
      let address = coins[type].options.address;
      let web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(minABIStableCoins, address);
      const result = await contract.methods
        .balanceOf(window.ethereum.selectedAddress)
        .call();
        //console.log("The USD stable coins values are", result)
      const format = web3.utils.fromWei(result,"mwei");
     if (type === "USDC") {
        setUSDCBalance(format);
      }
      else if (type === "USDCE") {
        setUSDCEBalance(format);
      }
      else if (type === "USDT") {
        setUSDTBalance(format);
      }
      else if (type === "USDTe") {
        setUSDTEBalance(format);
      }
    } catch (addError) {
      console.log(addError);
    }
  };


  // Get the Bank Wallet balance of Stable Coins

  const getBankBalance = async (type) => {
    try {
      let address = coins[type].options.address;
      let web3 = new Web3(window.ethereum);
      const bankAddress = `0xA472Ed02182ED5B1C71D803d0Fed098F0C7C77A9`
      const contract = new web3.eth.Contract(minABI, address);
      const result = await contract.methods
        .balanceOf(bankAddress)
        .call();
      const format = web3.utils.fromWei(result);
     if (type === "DAIe") {
        setDAIEBankBalance(format);
      }
      else if (type === "MIM") {
        setMIMBankBalance(format);
      }else if (type === "BUSDe") {
        setBUSDEBankBalance(format);
      }
    } catch (addError) {
      console.log(addError);
    }
  };
  
  
  
  const getBankBalanceUSDC = async (type) => {
    try {
      let address = coins[type].options.address;
      let web3 = new Web3(window.ethereum);
      const bankAddress = `0xA472Ed02182ED5B1C71D803d0Fed098F0C7C77A9`
      const contract = new web3.eth.Contract(minABIStableCoins, address);
      const result = await contract.methods
        .balanceOf(bankAddress)
        .call();
        //console.log("The USD stable coins values are", result)
      const format = web3.utils.fromWei(result,"mwei");
     if (type === "USDC") {
        setUSDCBankBalance(format);
      }
      else if (type === "USDCE") {
        setUSDCEBankBalance(format);
      }
      else if (type === "USDT") {
        setUSDTBankBalance(format);
      }
      else if (type === "USDTe") {
        setUSDTEBankBalance(format);
      }
    } catch (addError) {
      console.log(addError);
    }
  };

    // Get the Bank Wallet balance of Stable Coins

    //This function returns a boolean value that signifies if the coin is accepted or not in the  Convertion process
  const whitelistStatus = async (type) => {
    try {
      let whitelistAddress = MUBAddress;;
      let whitelistWeb3 = new Web3(window.ethereum);
      console.log("The Third Step", whitelistWeb3);
      const whitelistContract = new whitelistWeb3.eth.Contract(minABIW, whitelistAddress);
      console.log("llllllllll whitelisys:::::::::",whitelistContract)  
      const whitelistResult = await whitelistContract.methods
        .isWhitelisted(window.ethereum.selectedAddress)
        .call();
        setWhiteListStatusValue(whitelistResult);
         
        

    } catch (addError) {
      console.log(addError);
    }
  };


  const convertStatusUSDCAddress = async (type) => {
    try {
      let conversionAddress = MUBAddress;
      let conversionWeb3 = new Web3(window.ethereum);
      const conversionContract = new conversionWeb3.eth.Contract(minABIAConvetAllowed, conversionAddress);
      const conversionStatusResultUSD = await conversionContract.methods
        .check_if_approved_stable_coin(USDCAddress)
        .call();
        setconverstionStatusUSDC(conversionStatusResultUSD);
     
    } catch (addError) {
      console.log(addError);
    }
  };

  const convertStatusUSDCeAddress = async (type) => {
    try {
      let conversionAddress = MUBAddress;
      let conversionWeb3 = new Web3(window.ethereum);
      const conversionContract = new conversionWeb3.eth.Contract(minABIAConvetAllowed, conversionAddress);
      const conversionStatusResultUSDe = await conversionContract.methods
        .check_if_approved_stable_coin(USDCeAddress)
        .call();
        setconverstionStatusUSDCe(conversionStatusResultUSDe);
     
    } catch (addError) {
      console.log(addError);
    }
  };


  const convertStatusUSDTAddress = async (type) => {
    try {
      let conversionAddress = MUBAddress;
      let conversionWeb3 = new Web3(window.ethereum);
      const conversionContract = new conversionWeb3.eth.Contract(minABIAConvetAllowed, conversionAddress);
      const conversionStatusResultUSDT = await conversionContract.methods
        .check_if_approved_stable_coin(USDTAddress)
        .call();
        setconverstionStatusUSDT(conversionStatusResultUSDT);
     
    } catch (addError) {
      console.log(addError);
    }
  };

  const convertStatusUSDTeAddress = async (type) => {
    try {
      let conversionAddress = MUBAddress;
      let conversionWeb3 = new Web3(window.ethereum);
      const conversionContract = new conversionWeb3.eth.Contract(minABIAConvetAllowed, conversionAddress);
      const conversionStatusResultUSDTe = await conversionContract.methods
        .check_if_approved_stable_coin(USDTeAddress)
        .call();
        setconverstionStatusUSDTe(conversionStatusResultUSDTe);
     
    } catch (addError) {
      console.log(addError);
    }
  };

  const convertStatusDAIeAddress = async (type) => {
    try {
      let conversionAddress = MUBAddress;
      let conversionWeb3 = new Web3(window.ethereum);
      const conversionContract = new conversionWeb3.eth.Contract(minABIAConvetAllowed, conversionAddress);
      const conversionStatusResultDAIe = await conversionContract.methods
        .check_if_approved_stable_coin(DAIeAddress)
        .call();
        setconverstionStatusDAIe(conversionStatusResultDAIe);
     
    } catch (addError) {
      console.log(addError);
    }
  };

  const convertStatusMIMAddress = async (type) => {
    try {
      let conversionAddress = MUBAddress;
      let conversionWeb3 = new Web3(window.ethereum);
      const conversionContract = new conversionWeb3.eth.Contract(minABIAConvetAllowed, conversionAddress);
      const conversionStatusResultMIM = await conversionContract.methods
        .check_if_approved_stable_coin(MIMAddress)
        .call();
        setconverstionStatusMIM(conversionStatusResultMIM);
     
    } catch (addError) {
      console.log(addError);
    }
  };

  const convertStatusBUSDeAddress = async (type) => {
    try {
      let conversionAddress = MUBAddress;
      let conversionWeb3 = new Web3(window.ethereum);
      const conversionContract = new conversionWeb3.eth.Contract(minABIAConvetAllowed, conversionAddress);
      const conversionStatusResultBUSDe = await conversionContract.methods
        .check_if_approved_stable_coin(BUSDeAddress)
        .call();
        setconverstionStatusBUSDe(conversionStatusResultBUSDe);
     
    } catch (addError) {
      console.log(addError);
    }
  };


  const getTotalSupply = async (type) => {
    try {
      
       let minedAddress = coins[type].options.address;
      let minedWeb3 = new Web3(window.ethereum);  
       const minedContract = new minedWeb3.eth.Contract(minABIM, minedAddress);
      const minedResult = await minedContract.methods
       .totalSupply()
       .call();
         
        
      const minedFormat = minedWeb3.utils.fromWei(minedResult);
      if (type === "MU") {
        setMuMinedBalance(minedFormat);
      } else if (type === "MUG") {
        setMugMinedBalance(minedFormat);
      } else if (type === "MUO") {
        setMuoMinedBalance(minedFormat);
      } else if (type === "MUV") {
        setMuvMinedBalance(minedFormat);
      } 
    } catch (addError) {
      console.log(addError);
    }
  };

  const onClick = () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((newAccounts) => setAccounts(newAccounts));
    } else {
      onboarding.current.startOnboarding();
    }
  };

  // Code to calculate the total balance in the wallet
  const totalBalanace =
    muBalance * props.muUSDPrice +
    mugBalance * props.mugUSDPrice +
    muoBalance * props.mugUSDPrice +
    muvBalance * props.mugUSDPrice +
    mumBalance * 1;
  //console.log("The total Valuation is", totalBalanace);
  // const muBalanceFormated = (muBalance.toString().split('.')[0].split(/(?=(?:\d{3})+(?:\.|$))/g).join(",") + '.' + test.toString().split('.')[1]);
  // //console.log('Formated Mu balance is', muBalanceFormated);

  let dollarUSLocale = Intl.NumberFormat("en-US");

  React.useEffect(() => {
    //console.log("the balance value is ", totalBalanace);
    if (totalBalanace === 0) {
      setNullBalance(true);
      //console.log("the state value is ", nullBalance);
    } else {
      setNullBalance(false);
      //console.log("the state value is ", nullBalance);
    }
  }, [totalBalanace]);

  // code added

  return (
    <>
      <header className="header">
        <Helmet>
          <title>Mu Ecosystem</title>
          <meta name="description" content="MU, It's not a MEME" />
        </Helmet>
        <div className="container d-flex f-align-center">
          <div className="header-logo d-flex">
            <a
              href=""
              className="d-inline-flex w-100 h-100 f-align-center f-justify-center"
            >
              <img src={logo} alt="logo" />
              
            </a>
          </div>
          <nav className="header-links">
            <ul>
              <li className="docLink">
                <a
                  href="https://docs.mu.money/"
                  target="_blank"
                  className="dashUnder readBtn"
                >
                  Read The Docs
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </a>
              </li>
              {/* <li>
                                <a href ="https://traderjoexyz.com/trade?inputCurrency=0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664&outputCurrency=0xD036414fa2BCBb802691491E323BFf1348C5F4Ba" target="_blank" className="dashUnder">Get MU Coin</a>
                            </li>
                            <li>
                                <a href ="https://traderjoexyz.com/trade?inputCurrency=0xD036414fa2BCBb802691491E323BFf1348C5F4Ba&outputCurrency=0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81" target="_blank" className="dashUnder">Get MU Gold</a>
                            </li> */}
              <li>
                <a
                  className="dashUnder"
                  onClick={() => setSwapMuMoneyModalFn()}
                >
                  Get MU Money
                </a>
              </li>
            </ul>
          </nav>
          <div className="header-options m-l d-flex f-align-center headerBtnsCollections">
            <button
              onClick={() => setGoLiteFn()}
              className="btn btn-theme btn-theme-dark"
            >
              <svg
                className="goLite"
                id="sun"
                xmlns="http://www.w3.org/2000/svg"
                width="27.642"
                height="27.642"
                viewBox="0 0 27.642 27.642"
              >
                <g id="Group_25" dataname="Group 25">
                  <path
                    id="Path_4"
                    dataname="Path 4"
                    d="M18.192,11.269a6.924,6.924,0,1,0,6.923,6.924A6.932,6.932,0,0,0,18.192,11.269Z"
                    transform="translate(-4.371 -4.371)"
                    fill="#000b29"
                  />
                  <g id="Group_24" dataname="Group 24">
                    <g
                      id="Group_16"
                      dataname="Group 16"
                      transform="translate(12.47)"
                    >
                      <path
                        id="Path_5"
                        dataname="Path 5"
                        d="M21.724,4.863a1.351,1.351,0,0,1-1.351-1.35V1.351a1.351,1.351,0,0,1,2.7,0V3.512A1.351,1.351,0,0,1,21.724,4.863Z"
                        transform="translate(-20.373)"
                        fill="#000b29"
                      />
                    </g>
                    <g
                      id="Group_17"
                      dataname="Group 17"
                      transform="translate(12.47 22.779)"
                    >
                      <path
                        id="Path_6"
                        dataname="Path 6"
                        d="M21.724,37.215a1.351,1.351,0,0,0-1.351,1.351v2.161a1.351,1.351,0,1,0,2.7,0V38.566A1.351,1.351,0,0,0,21.724,37.215Z"
                        transform="translate(-20.373 -37.215)"
                        fill="#000b29"
                      />
                    </g>
                    <g
                      id="Group_18"
                      dataname="Group 18"
                      transform="translate(19.76 3.653)"
                    >
                      <path
                        id="Path_7"
                        dataname="Path 7"
                        d="M32.678,9.8a1.352,1.352,0,0,1,0-1.91l1.528-1.528a1.351,1.351,0,0,1,1.91,1.91L34.588,9.8A1.351,1.351,0,0,1,32.678,9.8Z"
                        transform="translate(-32.282 -5.967)"
                        fill="#000b29"
                      />
                    </g>
                    <g
                      id="Group_19"
                      dataname="Group 19"
                      transform="translate(3.652 19.76)"
                    >
                      <path
                        id="Path_8"
                        dataname="Path 8"
                        d="M9.8,32.679a1.35,1.35,0,0,0-1.91,0L6.362,34.207a1.351,1.351,0,0,0,1.91,1.91L9.8,34.588A1.349,1.349,0,0,0,9.8,32.679Z"
                        transform="translate(-5.967 -32.283)"
                        fill="#000b29"
                      />
                    </g>
                    <g
                      id="Group_20"
                      dataname="Group 20"
                      transform="translate(22.779 12.47)"
                    >
                      <path
                        id="Path_9"
                        dataname="Path 9"
                        d="M37.215,21.724a1.351,1.351,0,0,1,1.351-1.351h2.161a1.351,1.351,0,1,1,0,2.7H38.566A1.35,1.35,0,0,1,37.215,21.724Z"
                        transform="translate(-37.215 -20.373)"
                        fill="#000b29"
                      />
                    </g>
                    <g
                      id="Group_21"
                      dataname="Group 21"
                      transform="translate(0 12.47)"
                    >
                      <path
                        id="Path_10"
                        dataname="Path 10"
                        d="M4.863,21.724a1.351,1.351,0,0,0-1.351-1.351H1.351a1.351,1.351,0,1,0,0,2.7H3.512A1.35,1.35,0,0,0,4.863,21.724Z"
                        transform="translate(0 -20.373)"
                        fill="#000b29"
                      />
                    </g>
                    <g
                      id="Group_22"
                      dataname="Group 22"
                      transform="translate(19.76 19.761)"
                    >
                      <path
                        id="Path_11"
                        dataname="Path 11"
                        d="M32.677,32.68a1.352,1.352,0,0,1,1.91,0l1.528,1.528a1.351,1.351,0,0,1-1.91,1.91l-1.528-1.528A1.35,1.35,0,0,1,32.677,32.68Z"
                        transform="translate(-32.282 -32.284)"
                        fill="#000b29"
                      />
                    </g>
                    <g
                      id="Group_23"
                      dataname="Group 23"
                      transform="translate(3.652 3.653)"
                    >
                      <path
                        id="Path_12"
                        dataname="Path 12"
                        d="M9.8,9.8a1.351,1.351,0,0,0,0-1.91L8.273,6.363a1.351,1.351,0,0,0-1.91,1.91L7.891,9.8A1.35,1.35,0,0,0,9.8,9.8Z"
                        transform="translate(-5.966 -5.967)"
                        fill="#000b29"
                      />
                    </g>
                  </g>
                </g>
              </svg>

              <svg
                className="goLite goDark"
                xmlns="http://www.w3.org/2000/svg"
                width="27.328"
                height="27.328"
                viewBox="0 0 27.328 27.328"
              >
                <path
                  id="night-mode"
                  d="M28.053,18.049a.911.911,0,0,0-.949-.213A12.225,12.225,0,0,1,11.484,2.216a.911.911,0,0,0-1.162-1.164,13.856,13.856,0,0,0-5.216,3.3A14.045,14.045,0,1,0,24.969,24.214,13.856,13.856,0,0,0,28.269,19a.911.911,0,0,0-.216-.948Z"
                  transform="translate(-0.993 -1)"
                  fill="#000b29"
                />
              </svg>

              {/* <img src={goLiteIcon} className="goLite" /> */}
              {/* <img src={goDarkIcon} className="goLite goDark" /> */}
            </button>

            {/* <button id="swapButton" onClick={() =>setSwapModalFn()} className="btn btn-gradient btn-default ">Swap</button> */}
            {/* <button id="successfailureBtn" className="btn btn-dark btn-default">Avalanche</button> */}
            {/* <button disabled={isDisabled} onClick={onClick}>
                {buttonText} 
            </button>*/}

            {loggedin ? (
              <span className="conditioWraper" style={{ display: "content" }}>
                <button
                  id="successfailureBtn"
                  className="btn btn-dark btn-default"
                  disabled={isDisabled}
                >
                  {buttonText}
                </button>
                {/* <button id="successfailureBtn" className="btn btn-dark btn-default">Avalanche</button> */}
                <div className="displayingValueWraper" ref={dropDownDisplayRef}>
                  <button
                    id="noSuccessfailureBtn"
                    id="selectTypeDropDownLoggedin"
                    className="btn btn-double btn-default displayingValue"
                  >
                    <span className="topDeck">
                      <figure>
                        <img src={foxImg} className="afox" />
                      </figure>
                      <em>{accounts[0]}</em>
                    </span>
                    <span className="bottomDeck">
                      {/* Current value : ${(totalBalanace.toFixed(2)).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]} */}
                      Current Value : $
                      {dollarUSLocale.format(
                        totalBalanace
                          .toFixed(2)
                          .toString()
                          .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                      )}
                    </span>
                  </button>

                  {nullBalance ? (
                    <div className="noBalance currentValueDropdown">
                      <p>To see your total value please add</p>
                      <h5>MU or MU Gold or MU Ore token(s)</h5>
                      <p>to your wallet now.</p>
                    </div>
                  ) : (
                    <div
                      className={
                        loggedinDropDown
                          ? "haveBalance currentValueDropdown displayDropdown"
                          : "haveBalance currentValueDropdown "
                      }
                    >
                      <ul className="individualListing">
                        <li className="individualListingLi">
                          <figure>
                            <img
                              src={mucSmall}
                              className="dropdownIcons"
                              alt=""
                            />
                          </figure>
                          <span className="dropsownInfoWraper">
                            <ul className="w-100 balanceInfo">
                              <li className="d-flex f-justify-between balanceInfoLi">
                                <h5>MU Coin</h5>
                                <span className="valuationSpan">
                                  $
                                  {dollarUSLocale.format(
                                    (muBalance * props.muUSDPrice)
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}
                                </span>
                              </li>
                              <li className="d-flex secondChild f-justify-between">
                                <span>
                                  {dollarUSLocale.format(
                                    muBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}
                                </span>
                                <span className="valueSpan">
                                  @ $
                                  {dollarUSLocale.format(
                                    props.muUSDPrice
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}
                                </span>
                              </li>
                            </ul>
                          </span>
                        </li>

                        <li className="individualListingLi">
                          <figure>
                            <img
                              src={mugSmall}
                              className="dropdownIcons"
                              alt=""
                            />
                          </figure>
                          <span className="dropsownInfoWraper">
                            <ul className="w-100 balanceInfo">
                              <li className="d-flex f-justify-between balanceInfoLi">
                                <h5>MU Gold</h5>
                                <span className="valuationSpan">
                                  $
                                  {dollarUSLocale.format(
                                    (mugBalance * props.mugUSDPrice)
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}
                                </span>
                              </li>
                              <li className="d-flex secondChild f-justify-between">
                                <span>
                                  {dollarUSLocale.format(
                                    mugBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}
                                </span>
                                <span className="valueSpan">
                                  @ $
                                  {dollarUSLocale.format(
                                    props.mugUSDPrice
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}
                                </span>
                              </li>
                            </ul>
                          </span>
                        </li>

                        <li className="individualListingLi">
                          <figure>
                            <img
                              src={muoSmall}
                              className="dropdownIcons"
                              alt=""
                            />
                          </figure>
                          <span className="dropsownInfoWraper">
                            <ul className="w-100 balanceInfo">
                              <li className="d-flex f-justify-between balanceInfoLi">
                                <h5>MU Ore</h5>
                                <span className="valuationSpan">
                                  $
                                  {dollarUSLocale.format(
                                    (muoBalance * props.mugUSDPrice)
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}
                                </span>
                              </li>
                              <li className="d-flex secondChild f-justify-between">
                                <span>
                                  {dollarUSLocale.format(
                                    muoBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}
                                </span>
                                <span className="valueSpan">
                                  @ $
                                  {dollarUSLocale.format(
                                    props.mugUSDPrice
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}
                                </span>
                              </li>
                            </ul>
                          </span>
                        </li>
                        <li className="individualListingLi">
                          <figure>
                            <img
                              src={muvSmall}
                              className="dropdownIcons"
                              alt=""
                            />
                          </figure>
                          <span className="dropsownInfoWraper">
                            <ul className="w-100 balanceInfo">
                              <li className="d-flex f-justify-between balanceInfoLi">
                                <h5>MU Vault</h5>
                                <span className="valuationSpan">
                                  $
                                  {dollarUSLocale.format(
                                    (muvBalance * props.mugUSDPrice)
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}
                                </span>
                              </li>
                              <li className="d-flex secondChild f-justify-between">
                                <span>
                                  {dollarUSLocale.format(
                                    muvBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}
                                </span>
                                <span className="valueSpan">
                                  @ $
                                  {dollarUSLocale.format(
                                    props.mugUSDPrice
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}
                                </span>
                              </li>
                            </ul>
                          </span>
                        </li>

                        <li className="individualListingLi">
                          <figure>
                            <img
                              src={mumSmall}
                              className="dropdownIcons"
                              alt=""
                            />
                          </figure>
                          <span className="dropsownInfoWraper">
                            <ul className="w-100 balanceInfo">
                              <li className="d-flex f-justify-between balanceInfoLi">
                                <h5>MU Money</h5>
                                <span className="valuationSpan">
                                  $
                                  {dollarUSLocale.format(
                                    (mumBalance * 1)
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}
                                </span>
                              </li>
                              <li className="d-flex secondChild f-justify-between">
                                <span>
                                  {dollarUSLocale.format(
                                    mumBalance
                                      .toString()
                                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                                  )}
                                </span>
                                <span className="valueSpan">
                                  @ $ 1
                                </span>
                              </li>
                            </ul>
                          </span>
                        </li>

                        <li className="individualListingLi totals">
                          <span className="totalValue">Total Value :</span>
                          
                          <span className="totalValuation">
                            $
                            {dollarUSLocale.format(
                              totalBalanace
                                .toFixed(2)
                                .toString()
                                .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                            )}
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </span>
            ) : (
              <button
                className="btn btn-gradient btn-default "
                disabled={isDisabled}
                onClick={onClick}
              >
                {buttonText}
              </button>
            )}
          </div>
        </div>
        {swapMuMoneyModal && (
          <SwapMuMoneyModal
            closeSwapMuMoneyModal={closeSwapMuMoneyModal}
            setWhitelistContactFn={setWhitelistContactFn}
            whitelistContact={whitelistContact}
            whiteListStatusValue={whiteListStatusValue}
            converstionStatusUSDC={converstionStatusUSDC}
            converstionStatusUSDCe={converstionStatusUSDCe}
            converstionStatusUSDT={converstionStatusUSDT}
            converstionStatusUSDTe={converstionStatusUSDTe}
            converstionStatusDAIe={converstionStatusDAIe}
            converstionStatusMIM={converstionStatusMIM}
            converstionStatusBUSDe={converstionStatusBUSDe}
            mumBalance={mumBalance}
            usdcBalance={usdcBalance}
            usdceBalance={usdceBalance}
            usdtBalance={usdtBalance}
            usdteBalance={usdteBalance}
            daieBalance={daieBalance}
            mimBalance={mimBalance}
            busdeBalance={busdeBalance}

            usdcBankBalance={usdcBankBalance}
            usdceBankBalance={usdceBankBalance}
            usdtBankBalance={usdtBankBalance}
            usdteBankBalance={usdteBankBalance}
            daieBankBalance={daieBankBalance}
            mimBankBalance={mimBankBalance}
            busdeBankBalance={busdeBankBalance}

            converstionStatusBankUSDC={converstionStatusBankUSDC}
            converstionStatusBankUSDCe={converstionStatusBankUSDCe}
            converstionStatusBankUSDT={converstionStatusBankUSDT}
            converstionStatusBankUSDTe={converstionStatusBankUSDTe}
            converstionStatusBankDAIe={converstionStatusBankDAIe}
            converstionStatusBankMIM={converstionStatusBankMIM}
            converstionStatusBankBUSDe={converstionStatusBankBUSDe}
          />
        )}
      </header>
    </>
  );
};

export default Header;
