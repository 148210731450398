import React from "react";
import info from "../assets/images/info.svg";
function ToolTip({ content, text }) {
  return (
    <p className="tooltip_up">
      <p className="d-flex gap-1">
        {content && (
          <div className="imgHover d-flex">
            <img src={info} alt="info" width={18} />
            <div className="tooltip">{content}</div>
          </div>
        )}
        {text}:
      </p>
    </p>
  );
}

export default ToolTip;
