import React from 'react';
//import { Link} from "react-router-dom";
// import Sidebarfile from './sidebarfile/Sidebarfile';
// import footer1 from "../assets/images/Group100.svg";
// import footer2 from "../assets/images/Group101.svg";
// import footer3 from "../assets/images/Group102.svg";
// import footer4 from "../assets/images/Group103.svg";
// import footer5 from "../assets/images/Group104.svg";
// import footer6 from "../assets/images/Group105.svg";

  const Footer = () => {
    let year = new Date().getFullYear();
    //console.log(year); 
  return (
    
    <footer className="footer">
                <div className="container d-flex">
                    <div className="footerLink">
                        <ul className="d-flex">
                            <li>
                            <a href="https://twitter.com/mucoinofficial" target="_blank" rel="noreferrer">
                                    {/* <img src={footer1} alt="dsdfsad"/> */}


                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                        <g id="Group_104" dataname="Group 104" transform="translate(-463 -943)">
                                            <g id="Ellipse_10" dataname="Ellipse 10" transform="translate(463 943)"
                                                fill="#000b2c" stroke="#fff" strokeWidth="1">
                                                <circle cx="15" cy="15" r="15" stroke="none" />
                                                <circle cx="15" cy="15" r="14.5" fill="none" />
                                            </g>
                                            <g id="twitter_2_" dataname="twitter (2)" transform="translate(470 951)">
                                                <g id="Group_32" dataname="Group 32" transform="translate(0 0)">
                                                    <path id="Path_18" dataname="Path 18"
                                                        d="M15.092,35.556a.452.452,0,0,0-.424-.214l-.756.066.722-1.456a.452.452,0,0,0-.6-.607l-1.912.936a3.23,3.23,0,0,0-3.673.487,3.911,3.911,0,0,0-1.21,3.144,6.581,6.581,0,0,1-5.2-3.539.452.452,0,0,0-.761-.054,3.1,3.1,0,0,0-.2,3.251C.93,37.533.772,37.482.6,37.423a.452.452,0,0,0-.589.5A3.8,3.8,0,0,0,2.166,40.8a3.8,3.8,0,0,1-.474.131.452.452,0,0,0-.238.751,6.122,6.122,0,0,0,2.978,1.585,5.113,5.113,0,0,1-3.652.861.452.452,0,0,0-.35.755c.655.721,3.039,1.535,5.546,1.6q.149,0,.3,0a8.035,8.035,0,0,0,5.756-2.047,8.145,8.145,0,0,0,2.349-4.324,8.408,8.408,0,0,0,.015-2.834c-.005-.037-.011-.076-.016-.11l.707-1.14A.452.452,0,0,0,15.092,35.556ZM13.505,37.41A7.067,7.067,0,0,1,11.4,43.8,7.335,7.335,0,0,1,6,45.578a12.159,12.159,0,0,1-3.5-.6,5.591,5.591,0,0,0,.989-.208A4.943,4.943,0,0,0,5.722,43.3a.452.452,0,0,0-.276-.753A6.663,6.663,0,0,1,2.7,41.566a4.866,4.866,0,0,0,.848-.45.452.452,0,0,0-.135-.814,3.275,3.275,0,0,1-2.307-1.8,2.428,2.428,0,0,0,.963-.013.452.452,0,0,0,.226-.755,2.39,2.39,0,0,1-.609-2.2A7.712,7.712,0,0,0,3.945,37.72a7.5,7.5,0,0,0,3.806,1.129A.452.452,0,0,0,8.2,38.3a3.026,3.026,0,0,1,.852-2.862,2.318,2.318,0,0,1,2.8-.262.452.452,0,0,0,.442.025l.934-.457-.489.985a.452.452,0,0,0,.444.651l.658-.057-.3.489C13.442,36.975,13.463,37.12,13.505,37.41Z"
                                                        transform="translate(-0.001 -33.299)" fill="#fff" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>







                            </a>
                            </li>
                            {/* <li>
                            <a href="" target="_blank">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                        viewBox="0 0 30 30">
                                        <g id="Group_103" dataname="Group 103" transform="translate(-404 -943)">
                                            <g id="Ellipse_1" dataname="Ellipse 1" transform="translate(404 943)"
                                                fill="#000b2c" stroke="#fff" strokeWidth="1">
                                                <circle cx="15" cy="15" r="15" stroke="none" />
                                                <circle cx="15" cy="15" r="14.5" fill="none" />
                                            </g>
                                            <g id="reddit-logo" transform="translate(411 951)">
                                                <g id="Group_31" dataname="Group 31" transform="translate(0 0)">
                                                    <circle id="Ellipse_7" dataname="Ellipse 7" cx="1.132" cy="1.132"
                                                        r="1.132" transform="translate(4.767 7.011)" fill="#fff" />
                                                    <path id="Path_16" dataname="Path 16"
                                                        d="M16.8,9.771a2.5,2.5,0,0,0-2.455-2.537,2.384,2.384,0,0,0-1.291.384A9.139,9.139,0,0,0,8.819,6.465l.924-2.654,1.915.458a1.847,1.847,0,0,0-.019.189,1.933,1.933,0,1,0,.291-1L9.193,2.8,7.915,6.47A9.008,9.008,0,0,0,3.722,7.692c.023-.016.043-.034.065-.05a2.389,2.389,0,0,0-1.332-.408A2.5,2.5,0,0,0,0,9.771a2.54,2.54,0,0,0,1.231,2.185c.3,2.616,3.418,4.68,7.215,4.68,3.821,0,6.955-2.092,7.22-4.732A2.551,2.551,0,0,0,16.8,9.771ZM13.554,3.4a1.062,1.062,0,1,1-1.063,1.062A1.063,1.063,0,0,1,13.554,3.4ZM2.455,8.086A1.514,1.514,0,0,1,3,8.194a4.572,4.572,0,0,0-1.737,2.7A1.729,1.729,0,0,1,.851,9.771,1.648,1.648,0,0,1,2.455,8.086Zm5.991,7.7c-3.526,0-6.393-1.9-6.393-4.24S4.92,7.3,8.446,7.3s6.392,1.9,6.392,4.24S11.971,15.785,8.446,15.785Zm7.16-4.98a4.625,4.625,0,0,0-1.742-2.631,1.5,1.5,0,0,1,.485-.088,1.647,1.647,0,0,1,1.6,1.685A1.734,1.734,0,0,1,15.606,10.805Z"
                                                        transform="translate(-0.001 -2.544)" fill="#fff" />
                                                    <circle id="Ellipse_8" dataname="Ellipse 8" cx="1.132" cy="1.132"
                                                        r="1.132" transform="translate(9.635 7.011)" fill="#fff" />
                                                    <path id="Path_17" dataname="Path 17"
                                                        d="M15.391,22.5a3.767,3.767,0,0,1-4.42.024.426.426,0,0,0-.534.664,4.733,4.733,0,0,0,2.727.882,4.835,4.835,0,0,0,2.7-.858.426.426,0,1,0-.469-.711Z"
                                                        transform="translate(-4.806 -11.84)" fill="#fff" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    </a>
                            </li> */}
                            <li>
                            <a href="https://github.com/tier5development/mu-ecosystem" target="_blank" rel="noreferrer">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                        <g id="Group_102" dataname="Group 102" transform="translate(-340 -936)">
                                            <g id="Ellipse_1" dataname="Ellipse 1" transform="translate(340 936)" fill="#000b2c" stroke="#fff" strokeWidth="1">
                                                <circle cx="15" cy="15" r="15" stroke="none"></circle>
                                                <circle cx="15" cy="15" r="14.5" fill="none"></circle>
                                            </g>
                                            <path id="github" d="M11.894,16.7H5.5V14.924H4.49a2.5,2.5,0,0,1-2.328-1.612l-.373-1a.355.355,0,0,0-.333-.231H1.234A1.066,1.066,0,0,1,.181,10.838,1.1,1.1,0,0,1,1.269,9.95h.8A1.777,1.777,0,0,1,3.7,11.024l.422.982a.711.711,0,0,0,.653.431H5.5a3.075,3.075,0,0,1,.844-2.043l.1-.093A5.1,5.1,0,0,1,2.655,5.685,4.3,4.3,0,0,1,3.366,3.34V1.861A3.74,3.74,0,0,1,3.757.2l.1-.2h.613A2.825,2.825,0,0,1,6.484.83L6.653,1a7.337,7.337,0,0,1,4.1,0L10.917.83A2.825,2.825,0,0,1,12.925,0h.613l.1.2a3.74,3.74,0,0,1,.391,1.666V3.34a4.3,4.3,0,0,1,.711,2.345A5.1,5.1,0,0,1,10.953,10.3l.084.084a2.861,2.861,0,0,1,.857,2.034ZM6.209,15.99h4.975V12.419a2.145,2.145,0,0,0-.644-1.524l-.986-.964.631-.147c2.261-.533,3.842-2.217,3.842-4.1a3.606,3.606,0,0,0-.649-2.039l-.062-.089v-1.7a3.03,3.03,0,0,0-.227-1.15h-.164a2.118,2.118,0,0,0-1.506.626l-.48.475-.209-.071a6.611,6.611,0,0,0-4.069,0l-.209.071-.48-.475A2.118,2.118,0,0,0,4.467.711H4.3a3.03,3.03,0,0,0-.227,1.15v1.7l-.062.089a3.607,3.607,0,0,0-.648,2.039c0,1.883,1.581,3.567,3.842,4.1l.631.147-1,.977a2.4,2.4,0,0,0-.635,1.59.711.711,0,0,1-.706.653h-.72a1.421,1.421,0,0,1-1.306-.862L3.055,11.3a1.066,1.066,0,0,0-.982-.644h-.8a.383.383,0,0,0-.386.293.355.355,0,0,0,.346.418h.218a1.07,1.07,0,0,1,1,.693l.373.995A1.786,1.786,0,0,0,4.49,14.214H5.5a.711.711,0,0,1,.711.711Zm0,0" transform="translate(347.334 942.75)" fill="#fff"></path>
                                        </g>
                                    </svg>

                                </a>
                            </li>
                            <li>
                            <a href="https://www.facebook.com/mucoinofficial/" target="_blank" rel="noreferrer">
                              
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                        <g id="Group_101" dataname="Group 101" transform="translate(-286 -943)">
                                            <g id="Ellipse_1" dataname="Ellipse 1" transform="translate(286 943)" fill="#000b2c" stroke="#fff" strokeWidth="1">
                                                <circle cx="15" cy="15" r="15" stroke="none"></circle>
                                                <circle cx="15" cy="15" r="14.5" fill="none"></circle>
                                            </g>
                                            <path id="facebook_5_" dataname="facebook (5)" d="M5.605,14.562H3.273a.707.707,0,0,1-.706-.706V8.6H1.206A.707.707,0,0,1,.5,7.889V5.635a.707.707,0,0,1,.706-.706H2.567V3.8A3.843,3.843,0,0,1,3.583,1.047,3.66,3.66,0,0,1,6.282,0L8.06,0a.707.707,0,0,1,.705.706V2.8a.707.707,0,0,1-.706.706h-1.2c-.365,0-.458.073-.478.1s-.072.142-.072.433v.892H7.969a.718.718,0,0,1,.35.089.709.709,0,0,1,.363.618V7.889a.707.707,0,0,1-.706.706H6.312v5.26a.707.707,0,0,1-.706.706Zm-2.185-.854H5.458V8.213a.472.472,0,0,1,.472-.472h1.9V5.783H5.93a.472.472,0,0,1-.472-.472V4.037a1.454,1.454,0,0,1,.286-1,1.447,1.447,0,0,1,1.118-.384h1.05V.856l-1.631,0A2.7,2.7,0,0,0,3.421,3.8V5.311a.472.472,0,0,1-.472.472h-1.6V7.742h1.6a.472.472,0,0,1,.472.472ZM8.059.856h0Zm0,0" transform="translate(296.5 951)" fill="#fff"></path>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                            <li>
                            <a href="https://discord.gg/PfhkqVDcRk">
                                    {/* <img src={footer5} alt="dsdfsad"/> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                        <g id="Group_100" dataname="Group 100" transform="translate(-226 -943)">
                                            <g id="Ellipse_1" dataname="Ellipse 1" transform="translate(226 943)" fill="#000b2c" stroke="#fff" strokeWidth="1">
                                                <circle cx="15" cy="15" r="15" stroke="none"></circle>
                                                <circle cx="15" cy="15" r="14.5" fill="none"></circle>
                                            </g>
                                            <g id="discord" transform="translate(232 952)">
                                                <path id="Path_13" dataname="Path 13" d="M133.265,203.143a2.029,2.029,0,1,0,1.833,2.02A1.936,1.936,0,0,0,133.265,203.143Zm0,3.015a1.017,1.017,0,1,1,.809-1A.918.918,0,0,1,133.265,206.158Z" transform="translate(-126.944 -198.483)" fill="#fff"></path>
                                                <path id="Path_14" dataname="Path 14" d="M275.009,203.143a2.029,2.029,0,1,0,1.833,2.02A1.936,1.936,0,0,0,275.009,203.143Zm0,3.015a1.017,1.017,0,1,1,.809-1A.918.918,0,0,1,275.009,206.158Z" transform="translate(-263.848 -198.483)" fill="#fff"></path>
                                                <path id="Path_15" dataname="Path 15" d="M17.481,76.775c-.012-.217-.318-5.339-2.264-8.4a.512.512,0,0,0-.155-.156,8.2,8.2,0,0,0-4.112-1.545.512.512,0,0,0-.452.285L9.672,68.54q-.462-.03-.931-.031t-.931.031l-.825-1.582a.512.512,0,0,0-.453-.287,8.187,8.187,0,0,0-4.112,1.545.513.513,0,0,0-.155.156C.319,71.436.012,76.558,0,76.775a.512.512,0,0,0,.13.37,7.729,7.729,0,0,0,5.2,2.455h.04a.512.512,0,0,0,.4-.2L7.13,77.671a14.273,14.273,0,0,0,3.221,0l1.366,1.734a.512.512,0,0,0,.4.2h.04a7.73,7.73,0,0,0,5.2-2.455.513.513,0,0,0,.13-.37Zm-5.117,1.8-.854-1.084a9.569,9.569,0,0,0,3.378-1.334.512.512,0,0,0-.571-.851,10.3,10.3,0,0,1-5.577,1.435A10.3,10.3,0,0,1,3.164,75.3a.512.512,0,0,0-.571.851,9.572,9.572,0,0,0,3.378,1.334l-.854,1.084A6.762,6.762,0,0,1,1.039,76.61c.08-.992.492-5.086,2.03-7.595a8.335,8.335,0,0,1,3.159-1.292l.486.931a11.2,11.2,0,0,0-3.125.9.512.512,0,0,0,.436.927,11.325,11.325,0,0,1,4.715-.951,11.324,11.324,0,0,1,4.715.951.512.512,0,1,0,.436-.927,11.2,11.2,0,0,0-3.125-.9l.486-.931a8.336,8.336,0,0,1,3.159,1.292c1.536,2.506,1.95,6.6,2.03,7.6a6.723,6.723,0,0,1-4.078,1.96Z" transform="translate(0 -66.671)" fill="#fff"></path>
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="copyText">
                        <p>&copy; All rights reserved, {year}. Powered by <a href="https://tier5.us/" target="_blank" rel="noreferrer">Tier5</a></p>
                    </div>
                    <div className="freeFaucet">
                        <a href="">
                            <span>Get Free Tokens</span>
                            <figure className="d-inline-flex">
                            {/* <img src={footer6} alt="dsdfsad"/> */}
                            <svg id="faucet" xmlns="http://www.w3.org/2000/svg" width="45.741" height="37.7"
                                    viewBox="0 0 45.741 37.7">
                                    <defs>
                                        <linearGradient id="faucet-gradient" x1="0.5" x2="0.5" y2="1"
                                            gradientUnits="objectBoundingBox">
                                            <stop offset="0" stopColor="#bc00ff" />
                                            <stop offset="1" stopColor="#ff1aa1" />
                                        </linearGradient>
                                    </defs>
                                    <g id="Group_5" dataname="Group 5" transform="translate(0 8.13)">
                                        <g id="Group_4" dataname="Group 4" transform="translate(0)">
                                            <path id="Path_1" dataname="Path 1"
                                                d="M1.34,91A1.34,1.34,0,0,0,0,92.34v26.891a1.34,1.34,0,0,0,1.34,1.34,4.02,4.02,0,0,0,4.02-4.02V95.02A4.02,4.02,0,0,0,1.34,91Z"
                                                transform="translate(0 -91)" fill="url(#faucet-gradient)" />
                                        </g>
                                    </g>
                                    <g id="Group_7" dataname="Group 7" transform="translate(35.02 32.34)">
                                        <g id="Group_6" dataname="Group 6">
                                            <path id="Path_2" dataname="Path 2"
                                                d="M392,362v2.68a1.34,1.34,0,0,0,1.34,1.34h8.04a1.34,1.34,0,0,0,1.34-1.34V362Z"
                                                transform="translate(-392 -362)" fill="url(#faucet-gradient)" />
                                        </g>
                                    </g>
                                    <g id="Group_9" dataname="Group 9" transform="translate(8.04)">
                                        <g id="Group_8" dataname="Group 8">
                                            <path id="Path_3" dataname="Path 3"
                                                d="M116.98,17.6h-6.2a8,8,0,0,0-3.274-2.188V8.04h3.681a4.308,4.308,0,0,0,3.019,1.34,4.02,4.02,0,0,0,0-8.04,4.3,4.3,0,0,0-3.019,1.34h-3.516l-.317-.892a2.682,2.682,0,0,0-5.057,0l-.317.892H98.469A4.308,4.308,0,0,0,95.45,1.34a4.02,4.02,0,1,0,0,8.04,4.3,4.3,0,0,0,3.019-1.34h3.681v7.371A8,8,0,0,0,98.876,17.6H90V28.32h8.876a7.953,7.953,0,0,0,11.907,0H114.3a2.641,2.641,0,0,1,2.269,1.34H127.7V28.32A10.72,10.72,0,0,0,116.98,17.6Z"
                                                transform="translate(-90)" fill="url(#faucet-gradient)" />
                                        </g>
                                    </g>
                                </svg>
                            </figure>
                        </a>
                    </div>
                </div>
            </footer>

  );
}

export default Footer;