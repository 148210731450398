import React from 'react';
import { withRouter } from 'react-router-dom';

const ImagePage = ({ match }) => {
  const { filename } = match.params;
  
  return (
    <div className="image-container">
      <img 
        src={`/nft/images/${filename}.png`} 
        alt={`Image ${filename}`}
        onError={(e) => {
          e.target.onerror = null; 
          e.target.src = '/images/default.png';
        }}
      />
    </div>
  );
};

export default withRouter(ImagePage);
